import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 5rem;
  display: flex;
  gap: 12.5rem;
  justify-content: center;
  @media ${Utils.device.laptop} {
    flex-direction: column;
    align-items: center;
    gap: 0rem;
    padding: 2rem 1.5rem;
    img {
      display: none;
    }
  }
`;

export const InformationWrapper = styled.div`
  width: 100%;
  max-width: 390px;
  min-width: 312px;
  margin-top: 2.5rem;
  p {
    width: 100%;
    margin: 1rem 0;
  }
  @media ${Utils.device.laptop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 390px;
    min-width: 312px;
    margin: 0;
    .wrapperInputAccessCode {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const InformationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  max-width: 970px;
  min-width: auto;
  margin-top: -140px;
  button {
    min-width: auto;
  }
  @media ${Utils.device.laptop} {
    flex-direction: column;
    margin-top: 0;
    width: 90%;
    max-width: 390px;
    min-width: 312px;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`;

export const Helper = styled.span`
  display: flex;
  margin-top: 8px;
  color: #ea1f1f;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  line-height: 100%;
  .icon {
    padding-right: 4px;
  }
  span {
    color: #ea1f1f;
  }
`;
