import { useEffect, useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { getGuardian, postGuardian } from 'services/guardian.service';
import { postPatient } from 'services/patient.service';

import { SchemaInitialValues, schemas } from './schemas';
import * as S from './styles';

export function RegisterPatient() {
  const navigate = useNavigate();
  const { addToast } = Hooks.useToast();
  const { getUser } = Hooks.useUser();
  const instituteCode = getUser()?.instituteCode.toString();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const [loadingGetGuardian, setLoadingGetGuardian] = useState(false);
  const [disabledFields, setDisabledFields] = useState(true);
  const params = useLocation();
  const typeDocumentSelected = params.state?.typeDocumentSelected;
  const docSelected = params.state?.docSelected;

  useEffect(() => {
    const phoneInput = document.getElementById('phoneNumber');
    phoneInput.setAttribute('autocomplete', 'no');
  }, []);

  function navigatoToRegistration(patient, typeDocument, doc) {
    navigate('/sucesso-ao-cadastrar', {
      state: {
        route: '/tipo-de-atendimento',
        data: {
          typeDocumentSelected: typeDocument,
          docSelected: doc,
          patient,
        },
      },
    });
  }
  function callToError(response) {
    addToast({
      type: 'error',
      title: Utils.getError('title', response),
      message: Utils.getError('message', response) || 'Erro desconhecido',
    });
  }
  function HandleFieldsGuardian(disable, response, fieldsControl) {
    setDisabledFields(disable);
    fieldsControl.setFieldValue('guardianCode', response?.code || '');
    fieldsControl.setFieldValue(
      'nameGuardian',
      Utils.capitalize(response?.name) || ''
    );
    fieldsControl.setFieldValue(
      'birthDateGuardian',
      response?.birthDate ? Utils.splitDate(response?.birthDate) : ''
    );

    fieldsControl.setFieldValue('emailGuardian', response?.email || '');
    const PhoneResponsible = {
      ddi: response?.ddiPhoneNumber || '55',
      ddd: response?.dddPhoneNumber || '',
      phone: response?.phoneNumber || '',
      completeNumber:
        `${response?.ddiPhoneNumber}${response?.dddPhoneNumber}${response?.phoneNumber}` ||
        '',
    };
    fieldsControl.setFieldValue('phoneGuardian', PhoneResponsible);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: SchemaInitialValues(docSelected, typeDocumentSelected),
    validationSchema: schemas,
    onSubmit: values => {
      setLoadingButton(true);

      const guardianPhone = formik.values.phoneGuardian.phone?.length > 0 && {
        ddiPhoneNumber: formik.values.phoneGuardian.ddi,
        dddPhoneNumber: formik.values.phoneGuardian.ddd,
        phoneNumber: formik.values.phoneGuardian.phone,
      };
      const guardian = {
        name: Utils.capitalize(values.nameGuardian),
        cpf: Utils.verifyTypeDocument('cpf', false, formik.values),
        rne: Utils.verifyTypeDocument('rne', false, formik.values),
        identity: null,
        passport: Utils.verifyTypeDocument('passport', false, formik.values),
        birthDate: dayjs(values.birthDateGuardian).format('DD/MM/YYYY'),
        email: values.emailGuardian,
        ...guardianPhone,
      };

      const patientPhone = formik.values.phoneNumber.phone?.length > 0 && {
        ddiPhoneNumber: formik.values.phoneNumber.ddi,
        dddPhoneNumber: formik.values.phoneNumber.ddd,
        phoneNumber: formik.values.phoneNumber.phone,
      };
      const patient = {
        name: Utils.capitalize(values.name),
        identity: null,
        cpf: Utils.verifyTypeDocument('cpf', true, formik.values),
        rne: Utils.verifyTypeDocument('rne', true, formik.values),
        passport: Utils.verifyTypeDocument('passport', true, formik.values),
        gender: values.gender.value,
        email: values.email,
        birthDate: dayjs(values.birthDate).format('DD/MM/YYYY'),
        instituteCode,
        guardianCode: values.guardianCode ?? null,
        ...patientPhone,
      };

      if (
        (values.guardian.value === true && values.guardianCode === null) ||
        (values.guardian.value === true && values.guardianCode === '')
      ) {
        postGuardian(guardian)
          .then(responseGuardian => {
            postPatient({
              ...patient,
              guardianCode: responseGuardian.guardianCode.toString(),
            })
              .then(responsePatient => {
                Analytics.logEvent('Cadastro de Paciente', {
                  Gênero: values.gender.label,
                  Idade: dayjs().diff(patient.birthDate, 'year'),
                });
                const patientFormatted = {
                  code: responsePatient.patientCode,
                  ...patient,
                };
                navigatoToRegistration(
                  patientFormatted,
                  values.typeDocumentSelected,
                  values.docSelected
                );
              })
              .catch(({ response }) => {
                callToError(response);
              });
          })
          .catch(({ response }) => {
            callToError(response);
          })
          .finally(() => setLoadingButton(false));
      } else {
        postPatient(patient)
          .then(responsePatient => {
            Analytics.logEvent('Cadastro de Paciente', {
              Gênero: values.gender.label,
              Idade: dayjs().diff(patient.birthDate, 'year'),
            });
            const patientFormatted = {
              code: responsePatient.patientCode,
              guardianCode: null,
              ...patient,
            };
            navigatoToRegistration(
              patientFormatted,
              values.typeDocument,
              values.doc
            );
          })
          .catch(({ response }) => {
            Analytics.logEvent('Erro ao cadastrar paciente', response);
            callToError(response);
          })
          .finally(() => setLoadingButton(false));
      }
    },
  });

  function handleGetGuardian(value) {
    if (!formik.values.typeDocumentGuardian.value) {
      formik.setFieldTouched('typeDocumentGuardian', true);
    }
    if (!formik.values.docGuardian) {
      formik.setFieldTouched('docGuardian', true);
    }
    if (formik.values.typeDocumentGuardian.value && formik.values.docGuardian) {
      setLoadingGetGuardian(true);
      getGuardian(
        formik.values.typeDocumentGuardian.value,
        Utils.removeMask(
          'formik.values.typeDocumentGuardian.value',
          value ?? formik.values.docGuardian
        )
      )
        .then(response => {
          HandleFieldsGuardian(true, response, formik);
        })
        .catch(({ response }) => {
          const { statusCode } = JSON.parse(response?.data?.errorMessage);
          if (statusCode === 500) {
            HandleFieldsGuardian(false, response, formik);
            callToError(response);
          } else {
            setDisabledFields(true);
            callToError(response);
          }
        })
        .finally(() => setLoadingGetGuardian(false));
    }
  }

  function handleChangeAndSearchGuardian(element) {
    const unMask = Utils.removeMask(
      formik.values?.typeDocumentGuardian?.value,
      element.target.value
    );
    formik.setFieldValue('docGuardian', element.target.value);

    if (
      formik.values?.typeDocumentGuardian?.value === 'cpf' &&
      unMask.length === 11
    ) {
      handleGetGuardian(element.target.value);
    }
  }

  function handleCancel() {
    navigate('/');
  }

  const verificationTypedocGuardian =
    formik.values.typeDocumentGuardian.value === 'cpf';

  function handleChangeHasGuardian(selected) {
    const fieldsRefresh = [
      'typeDocumentGuardian',
      'docGuardian',
      'nameGuardian',
      'birthDateGuardian',
      'phoneGuardian',
      'emailGuardian',
      'birthDateGuardianInvalid',
    ];
    if (selected.value === false) {
      // eslint-disable-next-line array-callback-return
      fieldsRefresh.map(field => {
        formik.setFieldValue(field, formik.initialValues[field]);
        formik.setFieldTouched(field, false);
      });
    }
    formik.setFieldValue('guardian', selected);
  }

  return (
    <Components.Layout>
      <S.Wrapper>
        <S.Container>
          <S.WrapperTitle>
            <D.TmpTypography component="heading" size="large">
              Cadastro de paciente
            </D.TmpTypography>
          </S.WrapperTitle>
          <form onSubmit={formik.handleSubmit}>
            <S.WrapperContent>
              <D.TmpTypography component="heading" size="medium">
                Dados pessoais
              </D.TmpTypography>
              <S.WrapperInput>
                <D.TmpInput
                  id="name"
                  name="name"
                  label="Nome"
                  placeholder="Digite o nome completo"
                  value={formik.values.name}
                  helperIcon={
                    formik.touched.name && formik.errors.name && <Erro />
                  }
                  helperText={formik.touched.name && formik.errors.name}
                  onBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    formik.errors.name &&
                    formik.errors.name
                  }
                  className="inputName"
                  capitalize
                />
                <div className="inputDate">
                  <Components.CalendarInput
                    id="birthDate"
                    startDate={formik.values?.birthDate}
                    setStartDate={value => {
                      formik.setFieldValue('birthDate', value);
                    }}
                    onError={() => {
                      formik.setFieldTouched('birthDate', true);
                      formik.setFieldValue('birthDate', '');
                      formik.setFieldValue('birthDateInvalid', true);
                    }}
                    onCleanError={() => {
                      formik.setFieldTouched('birthDate', true);
                      formik.setFieldValue('birthDateInvalid', false);
                    }}
                    error={
                      formik.touched.birthDate && formik.errors.birthDateInvalid
                        ? formik.errors.birthDateInvalid
                        : formik.touched.birthDate &&
                          formik.errors.birthDate &&
                          formik.errors.birthDate
                    }
                    helperIcon={
                      formik.touched.birthDate &&
                      (formik.errors.birthDateInvalid ||
                        formik.errors.birthDate) && <Erro />
                    }
                    helperText={
                      formik.touched.birthDate && formik.errors.birthDateInvalid
                        ? formik.errors.birthDateInvalid
                        : formik.touched.birthDate && formik.errors.birthDate
                    }
                    handleOpenDatepicker={() => {
                      formik.setFieldTouched('birthDate', true);
                    }}
                  />
                </div>
                <div className="selectGender">
                  <D.TmpSelect
                    id="gender"
                    name="gender"
                    label="Sexo"
                    placeholder="Selecione o sexo"
                    listItems={[
                      {
                        label: 'Masculino',
                        value: 'M',
                      },
                      {
                        label: 'Feminino',
                        value: 'F',
                      },
                    ]}
                    handleChange={value => {
                      formik.setFieldValue('gender', value);
                    }}
                    error={
                      formik.touched.gender &&
                      formik.errors.gender &&
                      formik.errors.gender
                    }
                    helperIcon={
                      formik.touched.gender && formik.errors.gender && <Erro />
                    }
                    helperText={
                      formik.touched.gender &&
                      formik.errors.gender &&
                      formik.errors.gender.value
                    }
                    value={formik.values.gender}
                    onBlur={() => formik.setFieldTouched('gender', true)}
                  />
                </div>
                <div className="selectDocument">
                  <D.TmpSelect
                    id="typeDocument"
                    name="typeDocument"
                    label="Documento"
                    placeholder="Selecione o documento"
                    listItems={[
                      {
                        label: 'CPF',
                        value: 'cpf',
                      },
                      {
                        label: 'RNE',
                        value: 'rne',
                      },
                      {
                        label: 'Passaporte',
                        value: 'passport',
                      },
                    ]}
                    value={formik.values.typeDocument}
                    handleChange={value => {
                      formik.setFieldValue('typeDocument', value);
                      formik.setFieldTouched('doc', false);
                      formik.setFieldValue('doc', '');
                    }}
                    error={
                      formik.touched.typeDocument &&
                      formik.errors.typeDocument &&
                      formik.errors.typeDocument
                    }
                    helperIcon={
                      formik.touched.typeDocument &&
                      formik.errors.typeDocument && <Erro />
                    }
                    helperText={
                      formik.touched.typeDocument &&
                      formik.errors.typeDocument &&
                      formik.errors.typeDocument.values
                    }
                    onBlur={() => formik.setFieldTouched('typeDocument', true)}
                  />
                </div>
                <D.TmpInput
                  id="doc"
                  name="doc"
                  label="Nº do documento"
                  placeholder="Digite o número do documento"
                  value={formik.values.doc}
                  mask={formik.values.typeDocument.value === 'cpf' && 'cpf'}
                  helperIcon={
                    formik.touched.doc && formik.errors.doc && <Erro />
                  }
                  helperText={formik.touched.doc && formik.errors.doc}
                  onBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={
                    formik.touched.doc && formik.errors.doc && formik.errors.doc
                  }
                  className="inputDocument"
                />
                <DB2B.TmpPhoneInput
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Celular (opcional)"
                  value={formik.values.phoneNumber}
                  getValue={value => {
                    if (value !== formik.values.phoneNumber) {
                      formik.setFieldValue('phoneNumber', value);
                    }
                  }}
                  defaultValue={`${formik.values.phoneNumber.ddi}${formik.values.phoneNumber.ddd}${formik.values.phoneNumber.phone}`}
                  helperIcon={
                    formik.touched.phoneNumber &&
                    formik.errors.phoneNumber && <Erro />
                  }
                  helperText={
                    (formik.touched.phoneNumber &&
                      formik.errors.phoneNumber?.completeNumber) ||
                    (formik.touched.phoneNumber &&
                      formik.errors.phoneNumber?.phone)
                  }
                  error={
                    formik.touched.phoneNumber &&
                    formik.errors.phoneNumber &&
                    formik.errors.phoneNumber
                  }
                  defaultCountry="BR"
                  className="inputPhone"
                  onBlur={formik.handleBlur}
                />
                <D.TmpInput
                  id="email"
                  name="email"
                  label="E-mail (opcional)"
                  placeholder="Digite o e-mail"
                  handleChange={formik.handleChange}
                  className="inputEmail"
                  helperIcon={
                    formik.touched.email && formik.errors.email && <Erro />
                  }
                  helperText={formik.touched.email && formik.errors.email}
                  error={
                    formik.touched.email &&
                    formik.errors.email &&
                    formik.errors.email
                  }
                  onBlur={formik.handleBlur}
                />
                <div className="selectHasGuardian">
                  <D.TmpSelect
                    id="guardian"
                    name="guardian"
                    label="Possui responsável legal?"
                    placeholder="Selecione uma opção"
                    value={formik.values.guardian}
                    listItems={[
                      {
                        label: 'Sim',
                        value: true,
                      },
                      {
                        label: 'Não',
                        value: false,
                      },
                    ]}
                    handleChange={selected => {
                      handleChangeHasGuardian(selected);
                    }}
                    error={
                      formik.touched.guardian &&
                      formik.errors.guardian &&
                      formik.errors.guardian
                    }
                    helperIcon={
                      formik.touched.guardian &&
                      formik.errors.guardian && <Erro />
                    }
                    helperText={
                      formik.touched.guardian &&
                      formik.errors.guardian &&
                      formik.errors.guardian.value
                    }
                    onBlur={() => {
                      formik.setFieldTouched('guardian', true);
                      formik.setFieldTouched('birthdate', true);
                    }}
                  />
                </div>
              </S.WrapperInput>
              {formik.values.guardian.value && (
                <>
                  <D.TmpTypography
                    component="heading"
                    size="medium"
                    className="responsibleTitle"
                  >
                    Dados do responsável legal
                  </D.TmpTypography>
                  <S.WrapperInputResponsible
                    loadingGetGuardian={loadingGetGuardian}
                  >
                    <div className="selectDocumentResponsible">
                      <D.TmpSelect
                        id="typeDocumentGuardian"
                        name="typeDocumentGuardian"
                        label="Documento"
                        placeholder="Selecione o documento"
                        listItems={[
                          {
                            label: 'CPF',
                            value: 'cpf',
                          },
                          {
                            label: 'RNE',
                            value: 'rne',
                          },
                          {
                            label: 'Passaporte',
                            value: 'passport',
                          },
                        ]}
                        value={formik.values.typeDocumentGuardian}
                        handleChange={value => {
                          formik.setFieldValue('typeDocumentGuardian', value);
                          formik.setFieldTouched('docGuardian', false);
                          formik.setFieldValue('docGuardian', '');
                        }}
                        error={
                          formik.touched.typeDocumentGuardian &&
                          formik.errors.typeDocumentGuardian &&
                          formik.errors.typeDocumentGuardian
                        }
                        helperIcon={
                          formik.touched.typeDocumentGuardian &&
                          formik.errors.typeDocumentGuardian && <Erro />
                        }
                        helperText={
                          formik.touched.typeDocumentGuardian &&
                          formik.errors.typeDocumentGuardian &&
                          formik.errors.typeDocumentGuardian.value
                        }
                        onBlur={() =>
                          formik.setFieldTouched('typeDocumentGuardian', true)
                        }
                      />
                    </div>
                    <D.TmpInput
                      id="docGuardian"
                      name="docGuardian"
                      label="Nº do documento"
                      placeholder="Digite o Nº do documento"
                      value={formik.values.docGuardian}
                      handleChange={element =>
                        handleChangeAndSearchGuardian(element)
                      }
                      mask={verificationTypedocGuardian && 'cpf'}
                      helperIcon={
                        formik.values.typeDocumentGuardian.value !== '' &&
                        formik.touched.docGuardian &&
                        formik.errors.docGuardian && <Erro />
                      }
                      helperText={
                        formik.values.typeDocumentGuardian.value !== '' &&
                        formik.touched.docGuardian &&
                        formik.errors.docGuardian
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.values.typeDocumentGuardian.value !== '' &&
                        formik.touched.docGuardian &&
                        formik.errors.docGuardian &&
                        formik.errors.docGuardian
                      }
                      disabled={
                        formik.values.typeDocumentGuardian.value === '' && true
                      }
                      className={
                        formik.values.typeDocumentGuardian.value === ''
                          ? 'inputDocumentResponsible formControlForInputDisabled'
                          : 'inputDocumentResponsible'
                      }
                    />
                    <div className="buttonGetResponsible">
                      <D.TmpButtonIcon
                        typeButton="button"
                        size="lg"
                        icon={<ReactSVG src={I.busca} />}
                        handleClick={() => {
                          handleGetGuardian();
                        }}
                        className="btnMobile"
                        loading={loadingGetGuardian}
                      />
                      <D.TmpButton
                        size="sm"
                        type="secondary"
                        typeButton="button"
                        startIcon={<ReactSVG src={I.busca} />}
                        handleClick={() => {
                          handleGetGuardian();
                        }}
                        loading={loadingGetGuardian}
                        className="btnDesktop"
                      >
                        Buscar responsável
                      </D.TmpButton>
                    </div>
                    <D.TmpInput
                      id="nameGuardian"
                      name="nameGuardian"
                      label="Nome"
                      placeholder="Digite o nome completo"
                      value={formik.values.nameGuardian}
                      helperIcon={
                        formik.touched.nameGuardian &&
                        formik.errors.nameGuardian && <Erro />
                      }
                      helperText={
                        formik.touched.nameGuardian &&
                        formik.errors.nameGuardian
                      }
                      onBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.nameGuardian &&
                        formik.errors.nameGuardian &&
                        formik.errors.nameGuardian
                      }
                      disabled={disabledFields}
                      className={
                        disabledFields
                          ? 'inputNameGuardian formControlForInputDisabled'
                          : 'inputNameGuardian'
                      }
                      capitalize
                    />
                    <div className="inputDateResponsible">
                      <Components.CalendarInput
                        id="birthDateGuardian"
                        startDate={formik.values.birthDateGuardian}
                        setStartDate={value => {
                          formik.setFieldValue('birthDateGuardian', value);
                        }}
                        onError={() => {
                          formik.setFieldTouched('birthDateGuardian', true);
                          formik.setFieldValue('birthDateGuardian', '');
                          formik.setFieldValue(
                            'birthDateGuardianInvalid',
                            true
                          );
                        }}
                        onCleanError={() => {
                          formik.setFieldTouched('birthDateGuardian', true);
                          formik.setFieldValue(
                            'birthDateGuardianInvalid',
                            false
                          );
                        }}
                        error={
                          formik.touched.birthDateGuardian &&
                          formik.errors.birthDateGuardianInvalid
                            ? formik.errors.birthDateGuardianInvalid && <Erro />
                            : formik.touched.birthDateGuardian &&
                              formik.errors.birthDateGuardian && <Erro />
                        }
                        helperIcon={
                          formik.touched.birthDateGuardian &&
                          (formik.errors.birthDateGuardianInvalid ||
                            formik.errors.birthDateGuardian) && <Erro />
                        }
                        helperText={
                          formik.touched.birthDateGuardian &&
                          formik.errors.birthDateGuardianInvalid
                            ? formik.errors.birthDateGuardianInvalid
                            : formik.touched.birthDateGuardian &&
                              formik.errors.birthDateGuardian
                        }
                        disabled={disabledFields}
                        onBlur={() =>
                          formik.setFieldTouched('birthDateGuardian', true)
                        }
                        strictParsing
                        handleOpenDatepicker={() => {
                          formik.setFieldTouched('birthDateGuardian', true);
                        }}
                      />
                    </div>
                    <DB2B.TmpPhoneInput
                      id="phoneGuardian"
                      data-testid="phoneGuardian"
                      name="phoneGuardian"
                      label="Celular (opcional)"
                      getValue={value => {
                        if (value !== formik.values.phoneGuardian) {
                          formik.setFieldValue('phoneGuardian', value);
                        }
                      }}
                      defaultValue={`${formik.values.phoneGuardian.ddi}${formik.values.phoneGuardian.ddd}${formik.values.phoneGuardian.phone}`}
                      helperIcon={
                        formik.touched.phoneGuardian &&
                        formik.errors.phoneGuardian && <Erro />
                      }
                      helperText={
                        (formik.touched.phoneGuardian &&
                          formik.errors.phoneGuardian?.completeNumber) ||
                        (formik.touched.phoneGuardian &&
                          formik.errors.phoneGuardian?.phone)
                      }
                      error={
                        formik.touched.phoneGuardian &&
                        formik.errors.phoneGuardian &&
                        formik.errors.phoneGuardian
                      }
                      defaultCountry="BR"
                      onBlur={formik.handleBlur}
                      disabled={disabledFields}
                      className={
                        disabledFields
                          ? 'inputPhoneResponsible formControlForPhoneDisabled'
                          : 'inputPhoneResponsible'
                      }
                    />

                    <D.TmpInput
                      id="emailGuardian"
                      data-testid="emailGuardian"
                      name="emailGuardian"
                      label="E-mail (opcional)"
                      placeholder="Digite o e-mail"
                      value={formik.values.emailGuardian}
                      handleChange={formik.handleChange}
                      disabled={disabledFields}
                      className={
                        disabledFields
                          ? 'inputEmailResponsible formControlForInputDisabled'
                          : 'inputEmailResponsible'
                      }
                      helperIcon={
                        formik.touched.emailGuardian &&
                        formik.errors.emailGuardian && <Erro />
                      }
                      helperText={
                        formik.touched.emailGuardian &&
                        formik.errors.emailGuardian
                      }
                      error={
                        formik.touched.emailGuardian &&
                        formik.errors.emailGuardian &&
                        formik.errors.emailGuardian
                      }
                      onBlur={formik.handleBlur}
                    />
                  </S.WrapperInputResponsible>
                </>
              )}
            </S.WrapperContent>
            <S.WrapperButton>
              <div data-testid="cancel-register">
                <D.TmpButton
                  size="lg"
                  type="secondary"
                  handleClick={() => {
                    handleCancel();
                  }}
                >
                  Cancelar
                </D.TmpButton>
              </div>
              <div data-testid="register">
                <D.TmpButton
                  size="lg"
                  type="primary"
                  typeButton="submit"
                  loading={loadingButton}
                >
                  Cadastrar
                </D.TmpButton>
              </div>
            </S.WrapperButton>
          </form>
        </S.Container>
      </S.Wrapper>
    </Components.Layout>
  );
}
