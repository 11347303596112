import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 89px);
  @media ${Utils.device.laptop} {
    display: inherit;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 3rem 11.84rem;
  gap: 2rem;
  width: 100%;
  max-width: 1366px;
  box-sizing: border-box;
  @media ${Utils.device.laptop} {
    padding: 3rem 0;
    max-width: 390px;
  }
`;

export const WrapperTitle = styled.div`
  text-align: center;
  @media ${Utils.device.laptop} {
    text-align: start;
  }
`;

export const WrapperContent = styled.div``;

export const WrapperInput = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin: 1.5rem 0;
  gap: 2rem 1.5rem;
  .inputName {
    grid-column: 1 / 7;
  }
  .inputDate {
    grid-column: 7 / 11;
  }
  .selectGender {
    grid-row: 2;
    grid-column: 1 / 4;
  }
  .selectDocument {
    grid-row: 2;
    grid-column: 4 / 7;
  }
  .inputDocument {
    grid-row: 2;
    grid-column: 7 / 11;
  }
  .inputPhone {
    grid-row: 3;
    grid-column: 1 / 4;
  }
  div.inputEmail {
    align-self: flex-end;
    grid-row: 3;
    grid-column: 4 / 7;
  }
  input.inputEmail {
    align-self: center;
    grid-row: 3;
    grid-column: 4 / 7;
  }
  .selectHasGuardian {
    grid-row: 3;
    grid-column: 7 / 11;
  }
  @media ${Utils.device.desktop1320} {
    .selectHasGuardian {
      align-self: center;
    }
  }

  button {
    min-width: 0;
    width: 100%;
  }
  div {
    .input-wrapper {
      min-width: 0;
      width: 100%;
    }
    .tmp-icon:nth-child(2) {
      height: 54px;
    }
  }
  span {
    height: 1rem;
  }
  @media ${Utils.device.laptop} {
    gap: 2rem 0;
    .inputName {
      grid-column: 1 / 11;
    }
    .inputDate {
      grid-row: 2;
      grid-column: 1 / 11;
    }
    .selectGender {
      grid-row: 3;
      grid-column: 1 / 11;
      margin-bottom: 1.5rem;
    }
    .selectDocument {
      grid-row: 4;
      grid-column: 1 / 11;
      margin-bottom: 1.5rem;
    }
    .inputDocument {
      grid-row: 5;
      grid-column: 1 / 11;
    }
    .inputPhone {
      grid-row: 6;
      grid-column: 1 / 11;
    }
    div.inputEmail {
      align-self: flex-end;
      grid-row: 7;
      grid-column: 1 / 11;
    }
    input.inputEmail {
      align-self: center;
      grid-row: 7;
      grid-column: 1 / 11;
    }
    .selectHasGuardian {
      grid-row: 8;
      grid-column: 1 / 11;
      margin-bottom: 1rem;
    }
    button {
      min-width: 0;
      width: 100%;
    }
    div {
      .input-wrapper {
        min-width: 0;
        width: 100%;
      }
      .tmp-icon:nth-child(2) {
        height: 54px;
      }
    }
    span {
      height: 1rem;
    }
  }
`;

export const WrapperInputResponsible = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  margin-top: 1.5rem;
  gap: 2rem 1.5rem;
  .selectDocumentResponsible {
    grid-column: 1 / 4;
  }
  .inputDocumentResponsible {
    grid-column: 4 / 7;
  }
  .buttonGetResponsible {
    grid-column: 7 / 11;
    padding-top: 1.7rem;
    padding-right: 2rem;
    width: 230px;
    button {
      padding: 0;
      width: 100%;
    }
    path {
      fill: ${props => !props.loadingGetGuardian && '#2c6ef2'};
    }
    button:hover {
      path {
        fill: ${props => !props.loadingGetGuardian && '#0a3a99'};
      }
    }
  }
  .btnMobile {
    display: none;
  }
  .btnDesktop {
    display: flex;
  }
  .inputNameGuardian {
    grid-row: 2;
    grid-column: 1 / 7;
  }
  .inputDateResponsible {
    grid-row: 2;
    grid-column: 7 / 11;
  }
  .inputPhoneResponsible {
    grid-row: 3;
    grid-column: 1 / 4;
  }
  .inputEmailResponsible {
    grid-row: 3;
    grid-column: 4 / 7;
  }
  button {
    min-width: 0;
    width: 100%;
  }
  div {
    .input-wrapper {
      min-width: 0;
      width: 100%;
      .tmp-icon:nth-child(2) {
        height: 54px;
      }
    }
  }
  span {
    height: 1rem;
  }
  @media ${Utils.device.laptop} {
    gap: 2rem 0.7rem;
    .selectDocumentResponsible {
      grid-column: 1 / 11;
      margin-bottom: 1rem;
    }
    .inputDocumentResponsible {
      grid-row: 2;
      grid-column: 1 / 9;
    }
    .buttonGetResponsible {
      grid-row: 2;
      grid-column: 9 / 11;
      padding-left: 1rem;
      display: flex;
      width: 5px;
      .tmp-button {
        display: none;
      }
    }
    .btnMobile {
      display: flex;
    }
    .btnDesktop {
      display: none;
    }
    .inputNameGuardian {
      grid-row: 3;
      grid-column: 1 / 11;
    }
    .inputDateResponsible {
      grid-row: 4;
      grid-column: 1 / 11;
    }
    .inputPhoneResponsible {
      grid-row: 5;
      grid-column: 1 / 11;
    }
    .inputEmailResponsible {
      grid-row: 6;
      grid-column: 1 / 11;
    }
    button {
      min-width: 0;
      width: 100%;
    }
    div {
      .input-wrapper {
        min-width: 0;
        width: 100%;

        .tmp-icon:nth-child(2) {
          height: 54px;
        }
      }
    }
    span {
      height: 1rem;
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  gap: 1rem;
  .tmp-button {
    min-width: 132px;
  }
  @media ${Utils.device.laptop} {
    flex-direction: column;
    .tmp-button {
      width: 100%;
    }
  }
`;
