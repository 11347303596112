import { get, put } from './api_methods';

async function getInstitute(id) {
  const { data } = await get(`institute/id?instituteCode=${id}`);
  return data.result;
}

async function updateInstituteAgent(params) {
  const { data } = await put(`agent/update`, params);
  return data.result;
}

export { getInstitute, updateInstituteAgent };
