import { GlobalProviders } from '@b2b/core/src/contexts/GlobalProviders';
import { device } from '@b2b/core/src/utils';
import { Router } from 'routes';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  * {
    scroll-behavior: smooth;
  }
   @media ${device.laptop} {
    #drawer {
      text-align: center;
      > div {
        > div {
          margin-right: 0;
          margin-bottom: 32px;
        }
        flex-direction: column;
        > .tmp-button-group {
          flex-direction: column;
          width: 100%;
          button {
            white-space: nowrap;
          }
          > button:first-of-type {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .formControlForAutoCompleteDisabled{
    border-color: #666666;
    color: #666666 !important;
    .tmp-select__label {
      color: #666666 !important;
    }
    div:nth-child(2) {
      border-color: #666666;
      color: #666666 !important;
      > button {
        background: #E0E0E0;
        border-color: #666666;
        color: #666666 !important;
      }
    }
  }
  .formControlForSelectDisabled {
    border-color: #666666;
    color: #666666 !important;
    .tmp-select__label {
      color: #666666 !important;
    }
    div:nth-child(2) {
      border-color: #666666;
      color: #666666 !important;
      > button {
        background: #E0E0E0;
        border-color: #666666;
        color: #666666 !important;
      }
    }
  }
  .formControlForInputDisabled, .formControlForSelectDisabled, .formControlForDatePickerDisabled, .formControlForPhoneDisabled {
    input:disabled {
      color: #666666 !important;
      border-color: #666666;
    }
    label {
      color: #666666;
    }
    .input-wrapper {
      border-color: #666666;
      color: #666666 !important;
      background: #E0E0E0;
    }
  }
  .helperForInputDisabled, .formControlForSelectDisabled,.helperForDatepickerDisabled, .helperForPhoneInputDisabled  {
    span {
      color: #666666;
    }
  }
  @media ${device.laptop} {
    .tmp-button {
      width: 100%;
    }
  }
  #drawer {
    @media ${device.laptop} {
    .tmp-button {
      width: auto;
    }
  }
  }
`;

export function Main() {
  return (
    <GlobalProviders>
      <GlobalStyle />
      <Router />
    </GlobalProviders>
  );
}
