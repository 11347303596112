import useWebSocket from 'react-use-websocket';

import { put } from './api_methods';

async function leaveQueue(personCodeTasy, attendanceTasy) {
  const params = {
    personCodeTasy,
    attendanceTasy,
  };
  const { data } = await put(`attendance/cancel`, params);
  return data.result;
}

function useSocket(personCodeTasy) {
  const token = localStorage.getItem('@HSL-B2B:token');
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    process.env.REACT_APP_WEBSOCKET_URL,
    {
      queryParams: {
        Authorization: token,
      },
      onOpen: () =>
        sendMessage(`
        {"action": "sendCode", "personCodeTasy": "${personCodeTasy}"}`),
      onError: error => {
        return Promise.reject(error);
      },
      shouldReconnect: () => true,
      reconnectInterval: 10000,
    }
  );
  return { sendMessage, lastMessage, readyState, getWebSocket };
}

async function updateAttendanceStatus(attendanceCode, statusAttendance) {
  const { data } = await put(`attendance/change-status`, {
    attendanceCode: attendanceCode.toString(),
    statusAttendance: statusAttendance.toString(),
  });
  return data.result;
}

export { useSocket, updateAttendanceStatus, leaveQueue };
