import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 0rem 4rem 3.5rem 4rem;
  #modal-content {
    overflow: hidden;
  }

  @media ${Utils.device.laptop} {
    justify-content: center;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const MedicineContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
  @media ${Utils.device.laptop} {
    max-width: 345px;
    display: none;
  }
`;

export const MedicineContentMobile = styled.div`
  display: none;
  @media ${Utils.device.laptop} {
    display: flex;
    flex-direction: column;
    max-width: 345px;
  }
`;

export const WrapperImage = styled.div`
  margin-top: 5.6rem;

  @media ${Utils.device.laptop} {
    display: none;
  }
`;

export const ImageView = styled.img`
  height: 381px;
  width: 381px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
  @media ${Utils.device.laptop} {
    max-width: 345px;
  }
`;

export const WrapperDescriptiveComplaint = styled.div`
  display: flex;
  margin-top: ${({ hasSpecialties }) => (hasSpecialties ? '0.5rem' : '2.5rem')};
  gap: 1rem;
  > div {
    border: 0;
    padding: 0.5rem 1.5rem;
    border-radius: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    min-height: 0;
    display: flex;
    justify-content: space-between;
    .containerDescription {
      display: flex;
      path {
        fill: #000000;
      }
      a {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0;
        ::after {
          display: none;
        }
      }
      .tmp-typography {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
  path {
    fill: #2c6ef2;
  }
  a {
    padding: 0;
  }
`;

export const WrapperTypography = styled.div`
  display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  @media ${Utils.device.laptop} {
    display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  }
`;

export const WrapperTypographyOnlyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  strong {
    font-weight: 600;
  }
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin-top: 1.5rem;
  > div {
    height: 120px;
  }
  button {
    min-width: none;
    width: 100%;
  }
`;

export const SelectAttendance = styled.div``;

export const SelectSpecialties = styled.div``;

const getMarginTopButton = (hasSpecialties, hasDescriptionComplaint) => {
  if (hasSpecialties && !hasDescriptionComplaint) return '-13.5rem';
  if (!hasSpecialties && hasDescriptionComplaint) return '0rem';
  if (!hasDescriptionComplaint && !hasSpecialties) return '-3rem';
  if (hasSpecialties && hasDescriptionComplaint) return '-10rem';
  return '-2.5rem';
};
export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${({ useSpecialties, descriptionComplaint }) =>
    getMarginTopButton(useSpecialties, descriptionComplaint)};
  margin-left: -8rem;
  .formButton {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-width: 900px;
    min-width: 822px;
  }
  button {
    height: 56px;
    min-width: 0;
  }
  @media ${Utils.device.laptop} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 90%;
    margin-top: 0;
    margin-left: 0;
    .formButton {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 390px;
      min-width: 312px;
      /* padding: 0 1.5rem; */
    }
    button {
      width: 100%;
    }
  }
`;

export const ModalWrapper = styled.div`
  > div > div {
    max-width: 800px;
    padding-bottom: 2rem;
  }
  #descriptionComplaint {
    min-width: 750px;
    max-width: 750px;
    min-height: 100px;
    max-height: 500px;
  }
  #tmp-button-group-secondary {
    background-color: ${({ disabledButton }) =>
      disabledButton && 'rgba(0, 0, 0, 0.08)'};
    color: ${({ disabledButton }) => disabledButton && 'rgba(0, 0, 0, 0.32)'};
  }
  @media ${Utils.device.laptop} {
    margin-left: 0rem;
    #descriptionComplaint {
      min-width: 340px;
      max-height: 400px;
    }
  }
  @media ${Utils.device.mobile375} {
    #descriptionComplaint {
      min-width: 325px;
      max-width: 325px;
      max-height: 240px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 0;
`;

export const TitleModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
