/* eslint-disable react/prop-types */
import { useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Services, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { ReactSVG } from 'react-svg';
import { getInstitute, updateInstituteAgent } from 'services/institute.service';

import { InstituteData } from './instituteData';
import { PersonalData } from './personalData';
import { SchemaInitialValues, schemas } from './schemas';
import * as S from './styles';

export function MyData() {
  const { addToast } = Hooks.useToast();
  const { saveToken } = Hooks.useUser();
  const { setSpecialties } = Hooks.useUser();
  const [editType, setEditType] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReload, setLoadingReload] = useState(false);
  const [loadingHealthInsurance, setLoadingHealthInsurance] = useState(false);
  const [institute, setInstitute] = useState(false);
  const personalData = PersonalData();
  // const navigate = useNavigate();
  const {
    instituteCode,
    code,
    name,
    document,
    phoneNumber,
    ddiPhoneNumber,
    dddPhoneNumber,
    email,
    status,
    cpf,
    rne,
    passport,
    birthDate,
    accessToken,
    gender,
    typeDocument,
    statusDescription,
    healthProfessional,
    jobDescription,
    getPersonalData,
  } = personalData;

  const documentInstitute = Utils.completeDocument(institute).document;
  const typeDocumentInstitute = Utils.completeDocument(institute).typeDocument;

  function handleGetInstitution() {
    setLoadingReload(true);
    setLoadingHealthInsurance(true);
    getInstitute(instituteCode)
      .then(response => {
        setError(false);
        setInstitute(response);
      })
      .then(() => {
        Services.getSpecialtiesInstitutes(instituteCode).then(response => {
          const adaptSpecialties = response.map(item => {
            return {
              ...item,
              selected: true,
            };
          });
          setSpecialties(adaptSpecialties);
        });
      })
      .catch(({ response }) => {
        setError(true);
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => {
        setLoadingHealthInsurance(false);
        setLoadingReload(false);
      });
  }

  const instituteData = InstituteData(
    handleGetInstitution,
    institute,
    instituteCode
  );

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: schemas,
    initialValues: SchemaInitialValues(
      name,
      status,
      gender,
      typeDocument,
      statusDescription,
      document,
      phoneNumber,
      ddiPhoneNumber,
      dddPhoneNumber,
      email,
      healthProfessional,
      jobDescription,
      institute.name,
      institute.fantasyName,
      institute.status,
      institute.email,
      institute.phoneNumber,
      institute.ddiPhoneNumber,
      institute.dddPhoneNumber,
      institute.cep,
      institute.neighborhood,
      institute.address,
      institute.addressNumber,
      institute.complement,
      institute.state,
      institute.city,
      institute.hasCompanionRequired,
      documentInstitute,
      typeDocumentInstitute
    ),
    onSubmit: values => {
      setLoading(true);
      const initial = {
        agentCode: code.toString(),
        statusCode: status.toString(),
        jobDescription,
        healthProfessional: healthProfessional.toString(),
        name,
        ddiPhoneNumber,
        dddPhoneNumber,
        phoneNumber,
        email,
        gender,
        birthDate: birthDate && '19/04/1995',
      };
      function getUpdate(returnString, onlyCode, addStorage) {
        const storage = addStorage && {
          instituteCode,
          cpf,
          rne,
          passport,
          birthDate,
          accessToken,
        };
        const statusUser = addStorage
          ? { status, statusDescription }
          : { statusCode: returnString ? status.toString() : status };
        const codeUser = onlyCode
          ? { code: returnString ? code.toString() : code }
          : { agentCode: returnString ? code.toString() : code };
        const phoneUpdate = {
          ddiPhoneNumber: values?.phoneNumber.ddi,
          dddPhoneNumber: values?.phoneNumber.ddd,
          phoneNumber: values?.phoneNumber.phone,
        };

        const params = {
          ...codeUser,
          ...statusUser,
          ...storage,
          name: Utils.capitalize(values?.name),
          email: values?.email,
          jobDescription,
          gender: values?.gender.value,
          birthDate: '19/04/1995',
          healthProfessional: returnString
            ? healthProfessional.toString()
            : healthProfessional,
          ...phoneUpdate,
        };

        return params;
      }

      const userUpdateValues = getUpdate(true, false, false);
      const isEqual = Utils.compareObjects(initial, userUpdateValues);

      if (isEqual) {
        setLoadingReload(false);
        setLoading(false);
        setEditType(false);
      } else {
        updateInstituteAgent(userUpdateValues)
          .then(async res => {
            setLoadingHealthInsurance(true);
            if (res) {
              await Services.refreshToken().then(refresh => {
                saveToken(refresh.newAccessToken);
                formik.resetForm();
              });
            }
          })
          .then(() => {
            addToast({
              type: 'success',
              title: 'Dados alterados com sucesso',
              message: 'Suas alterações foram aplicadas em nosso sistema.',
            });
          })
          .catch(({ response }) => {
            formik.resetForm();
            addToast({
              type: 'error',
              title: Utils.getError('title', response),
              message:
                Utils.getError('message', response) || 'Erro desconhecido',
            });
          })
          .finally(() => {
            setEditType(false);
            setLoading(false);
            setLoadingHealthInsurance(false);
          });
      }
    },
  });

  function handleReset(resetForm) {
    resetForm();
  }

  function loadingFeedback() {
    return error ? (
      <Components.ReloadError
        data-testid="message-toast"
        marginTop="0px"
        loading={loadingHealthInsurance || loadingReload}
        reload={() => handleGetInstitution()}
        height={editType ? '612px' : '361px'}
      />
    ) : (
      <div className="loadingFeedback">
        <Components.Loading height="380px" />
      </div>
    );
  }

  return (
    <Components.Layout>
      <S.Wrapper>
        <S.Container>
          <div className="containerBreadcrumbs">
            <Components.Breadcrumbs />
          </div>
          <S.WrapperTypography editType={editType}>
            <D.TmpTypography component="heading" size="medium">
              {`${editType ? 'Editar meus dados' : 'Meus dados'}`}
            </D.TmpTypography>
            <D.TmpButtonIcon
              id="button-edit"
              size="lg"
              handleClick={() => setEditType(true)}
              disabled={editType}
              icon={<ReactSVG src={I.editar} />}
            />
          </S.WrapperTypography>
          <form onSubmit={formik.handleSubmit}>
            <S.WrapperCardShape>
              <div className="cardShape">
                <D.TmpShape>
                  <S.WrapperPersonalData>
                    <div className="personalDataTitle">
                      <D.TmpTypography component="heading" size="small">
                        DADOS PESSOAIS
                      </D.TmpTypography>
                    </div>
                    {getPersonalData.map(item => {
                      if (editType) {
                        return item.type === 'paragraph' &&
                          item.typeInput !== 'select' &&
                          item.typeInput !== 'checkboxGroup' ? (
                          <div
                            className={editType ? item.editClass : item.class}
                          >
                            {item.type === 'paragraph' &&
                              (item.mask === 'ddiphone' ? (
                                <DB2B.TmpPhoneInput
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  value={formik.values[item.name]}
                                  getValue={value => {
                                    if (value !== formik.values[item.name]) {
                                      formik.setFieldValue(item.name, value);
                                    }
                                  }}
                                  defaultValue={`${
                                    formik.values[item.name].ddi
                                  }${formik.values[item.name].ddd}${
                                    formik.values[item.name].phone
                                  }`}
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] && <Erro />
                                  }
                                  helperText={
                                    (formik.touched[item.name] &&
                                      formik.errors[item.name]
                                        ?.completeNumber) ||
                                    (formik.touched[item.name] &&
                                      formik.errors[item.name]?.phone)
                                  }
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  defaultCountry={
                                    formik.values[item.name].ddi !== null
                                      ? null
                                      : 'BR'
                                  }
                                  onBlur={formik.handleBlur}
                                />
                              ) : (
                                <D.TmpInput
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  disabled={item.disabled}
                                  value={formik.values[item.name]}
                                  handleChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  mask={item.mask}
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] && <Erro />
                                  }
                                  helperText={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name] &&
                                    formik.errors[item.name]
                                  }
                                  capitalize={item.capitalize}
                                />
                              ))}
                          </div>
                        ) : (
                          item.type === 'paragraph' && (
                            <div
                              className={editType ? item.editClass : item.class}
                            >
                              {item.typeInput === 'select' && (
                                <D.TmpSelect
                                  id={item.name}
                                  name={item.name}
                                  label={item.label}
                                  placeholder={item.placeholder}
                                  listItems={
                                    item.listItem ?? [
                                      {
                                        label: item.content,
                                        value: institute?.status,
                                      },
                                    ]
                                  }
                                  handleChange={value => {
                                    formik.setFieldValue(item.name, value);
                                  }}
                                  value={formik.values[item.name]}
                                  disabled={item.disabled}
                                />
                              )}
                              {item.typeInput === 'checkboxGroup' && (
                                <DB2B.TmpCheckboxGroup
                                  listItems={item?.listItems}
                                  mainKey={item.mainKey}
                                  selectedKey={item.selectedKey}
                                  label={item.label}
                                  name={item.name}
                                  showSelectedAsFalse={item.showSelectedAsFalse}
                                  handleChange={value => {
                                    formik.setFieldValue(item.name, value);
                                    formik.setFieldTouched(item.name, false);
                                    item.handleChange(value);
                                  }}
                                  helperIcon={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.description && (
                                      <Erro />
                                    )
                                  }
                                  helperText={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.description
                                  }
                                  helperTitle={item.helperTitle}
                                  error={
                                    formik.touched[item.name] &&
                                    formik.errors[item.name]?.description &&
                                    formik.errors[item.name]?.description
                                  }
                                  disabled={item.disabled}
                                  startLines={item.startLines}
                                  maxColumns={item.startLines}
                                />
                              )}
                            </div>
                          )
                        );
                      }
                      return (
                        <div className={item.class}>
                          <D.TmpTypography component={item.type} size="small">
                            {item.content}
                          </D.TmpTypography>
                        </div>
                      );
                    })}
                  </S.WrapperPersonalData>
                  <D.TmpDivider />
                  <S.WrapperInstitutionData>
                    <div className="institutionDataTitle">
                      <D.TmpTypography component="heading" size="small">
                        DADOS DA INSTITUIÇÃO
                      </D.TmpTypography>
                    </div>
                    {instituteData && !loadingHealthInsurance
                      ? instituteData.map(item => {
                          if (editType) {
                            return item.type === 'paragraph' &&
                              item.typeInput !== 'select' &&
                              item.typeInput !== 'checkboxGroup' ? (
                              <div
                                className={
                                  editType ? item.editClass : item.class
                                }
                              >
                                {item.type === 'paragraph' &&
                                  (item.mask === 'ddiphone' ? (
                                    <DB2B.TmpPhoneInput
                                      id={item.name}
                                      name={item.name}
                                      label={item.label}
                                      placeholder={item.placeholder}
                                      value={formik.values[item.name]}
                                      getValue={value => {
                                        if (
                                          value !== formik.values[item.name]
                                        ) {
                                          formik.setFieldValue(
                                            item.name,
                                            value
                                          );
                                        }
                                      }}
                                      defaultValue={`${
                                        formik.values[item.name].ddi
                                      }${formik.values[item.name].ddd}${
                                        formik.values[item.name].phone
                                      }`}
                                      helperIcon={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name] && <Erro />
                                      }
                                      helperText={
                                        (formik.touched[item.name] &&
                                          formik.errors[item.name]
                                            ?.completeNumber) ||
                                        (formik.touched[item.name] &&
                                          formik.errors[item.name]?.phone)
                                      }
                                      error={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name] &&
                                        formik.errors[item.name]
                                      }
                                      defaultCountry={
                                        formik.values[item.name].ddi !== null
                                          ? null
                                          : 'BR'
                                      }
                                      onBlur={formik.handleBlur}
                                      disabled={item.disabled}
                                    />
                                  ) : (
                                    <D.TmpInput
                                      id={item.name}
                                      name={item.name}
                                      label={item.label}
                                      placeholder={item.placeholder}
                                      disabled={item.disabled}
                                      value={formik.values[item.name]}
                                      handleChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      mask={item.mask}
                                      helperIcon={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name] && <Erro />
                                      }
                                      helperText={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name]
                                      }
                                      error={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name] &&
                                        formik.errors[item.name]
                                      }
                                      capitalize={item.capitalize}
                                    />
                                  ))}
                              </div>
                            ) : (
                              item.type === 'paragraph' && (
                                <div
                                  className={
                                    editType ? item.editClass : item.class
                                  }
                                >
                                  {item.typeInput === 'select' && (
                                    <D.TmpSelect
                                      id={item.name}
                                      name={item.name}
                                      label={item.label}
                                      placeholder={item.placeholder}
                                      listItems={[
                                        {
                                          label: item.content,
                                          value: institute?.status,
                                        },
                                      ]}
                                      handleChange={value => {
                                        formik.setFieldValue(item.name, value);
                                      }}
                                      value={formik.values[item.name]}
                                      disabled={item.disabled}
                                    />
                                  )}
                                  {item.typeInput === 'checkboxGroup' && (
                                    <DB2B.TmpCheckboxGroup
                                      listItems={item?.listItems}
                                      mainKey={item.mainKey}
                                      selectedKey={item.selectedKey}
                                      label={item.label}
                                      name={item.name}
                                      showSelectedAsFalse={
                                        item.showSelectedAsFalse
                                      }
                                      handleChange={value => {
                                        formik.setFieldValue(item.name, value);
                                        formik.setFieldTouched(
                                          item.name,
                                          false
                                        );
                                        item.handleChange(value);
                                      }}
                                      helperIcon={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name]
                                          ?.description && <Erro />
                                      }
                                      helperText={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name]?.description
                                      }
                                      helperTitle={item.helperTitle}
                                      error={
                                        formik.touched[item.name] &&
                                        formik.errors[item.name]?.description &&
                                        formik.errors[item.name]?.description
                                      }
                                      disabled={item.disabled}
                                      startLines={item.startLines}
                                      maxColumns={item.startLines}
                                    />
                                  )}
                                </div>
                              )
                            );
                          }
                          return (
                            <div className={item.class}>
                              <D.TmpTypography
                                component={item.type}
                                size="small"
                              >
                                {item.content}
                              </D.TmpTypography>
                            </div>
                          );
                        })
                      : loadingFeedback()}
                  </S.WrapperInstitutionData>
                </D.TmpShape>
              </div>
            </S.WrapperCardShape>
            {editType && (
              <S.WrapperFooterActions>
                <div data-testid="button-cancel">
                  <D.TmpButton
                    type="secondary"
                    size="lg"
                    handleClick={() => {
                      setEditType(false);
                      handleReset(formik.resetForm);
                    }}
                  >
                    Cancelar
                  </D.TmpButton>
                </div>
                <div data-testid="button-save">
                  <D.TmpButton size="lg" loading={loading}>
                    Salvar
                  </D.TmpButton>
                </div>
              </S.WrapperFooterActions>
            )}
          </form>
        </S.Container>
      </S.Wrapper>
    </Components.Layout>
  );
}
