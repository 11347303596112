import React from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components } from '@b2b/core';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

export function Home() {
  const navigate = useNavigate();
  function actionsContainer() {
    return (
      <S.ButtonContainer data-testid="button-start-service">
        <D.TmpButton
          size="lg"
          type="primary"
          typeButton="submit"
          startIcon={<ReactSVG src={I.seta_direita} />}
          handleClick={() => navigate('/identificacao-do-paciente')}
        >
          Iniciar Atendimento
        </D.TmpButton>
      </S.ButtonContainer>
    );
  }

  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Layout>
        <S.Wrapper>
          <S.ImageContainer>
            <S.ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}patientConsultation.png`}
            />
          </S.ImageContainer>
          <S.WrapperContent>
            <S.TypographyContainer>
              <D.TmpTypography component="heading" size="large">
                Bem vindo(a)
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                Para iniciar o seu atendimento com um de nossos profissionais,
                basta clicar no botão abaixo.
              </D.TmpTypography>
            </S.TypographyContainer>
          </S.WrapperContent>
        </S.Wrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
