import { Utils } from '@b2b/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

dayjs.extend(customParseFormat);

const fieldsSchemas = Yup.object().shape({
  value: Yup.string().required('Campo obrigatório').nullable(),
});

const typeDocumentGuardianSchemas = Yup.object().when('guardian[value]', {
  is: true,
  then: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório').nullable(),
  }),
  otherwise: Yup.object({
    value: Yup.string(),
  }),
});

const documentGuardianSchemas = Yup.string().when('guardian[value]', {
  is: true,
  then: Yup.string()
    .when('typeDocumentGuardian[value]', {
      is: 'rne',
      then: Yup.string()
        .min(6, 'Mínimo de 6 caracteres')
        .required('Campo obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    })
    .when('typeDocumentGuardian[value]', {
      is: 'passport',
      then: Yup.string()
        .min(6, 'Mínimo de 6 caracteres')
        .required('Campo obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    })
    .when('typeDocumentGuardian[value]', {
      is: 'cpf',
      then: Yup.string()
        .transform(value => value?.replace(/[^\d]/g, ''))
        .min(11, 'CPF inválido')
        .max(11, 'CPF inválido')
        .test('is-cpf', 'CPF inválido', value => {
          if (!value) {
            return true;
          }
          return Utils.validateCPF(Utils.removeMask('cpf', value));
        })
        .required('Campo obrigatório')
        .nullable(),
      otherwise: Yup.string().required('Campo obrigatório').nullable(),
    })
    .required('Campo obrigatório')
    .nullable(),
});

const birthDateGuardianSchemas = Yup.string().when('guardian[value]', {
  is: true,
  then: Yup.string()
    .test(
      'valid-birthDateGuardian',
      'O responsável deve ter mais de 18 anos',
      value => {
        if (value !== undefined) {
          return Utils.checkResponsibleAge(value) === true;
        }
        return Utils.checkResponsibleAge(value) === false;
      }
    )
    .required('Campo obrigatório')
    .nullable('Campo obrigatório'),
});

export const schemas = Yup.object().shape({
  name: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .required('Campo obrigatório'),
  birthDateInvalid: Yup.bool().oneOf([false], 'Campo inválido'),
  birthDate: Yup.string()
    .when('guardian[value]', {
      is: false,
      then: Yup.string().required('Campo obrigatório').nullable(),
      otherwise: Yup.string().required('Campo obrigatório').nullable(),
    })
    .required('Campo obrigatório'),
  doc: Yup.string()
    .when('typeDocument[value]', {
      is: 'rne',
      then: Yup.string()
        .min(6, 'Mínimo de 6 caracteres')
        .required('Campo obrigatório'),
      otherwise: Yup.string(),
    })
    .when('typeDocument[value]', {
      is: 'passport',
      then: Yup.string()
        .min(6, 'Mínimo de 6 caracteres')
        .required('Campo obrigatório'),
      otherwise: Yup.string(),
    })
    .when('typeDocument[value]', {
      is: 'cpf',
      then: Yup.string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .min(11, 'CPF inválido')
        .max(11, 'CPF inválido')
        .test('is-cpf', 'CPF inválido', value => {
          if (!value) {
            return true;
          }
          return Utils.validateCPF(Utils.removeMask('cpf', value));
        })
        .required('Campo obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    })
    .required('Campo Obrigatório'),
  docGuardian: documentGuardianSchemas,
  gender: fieldsSchemas,
  typeDocument: fieldsSchemas,
  guardian: Yup.object().shape({
    value: Yup.boolean().required('Campo obrigatório').nullable(),
  }),
  typeDocumentGuardian: typeDocumentGuardianSchemas,
  nameGuardian: Yup.string().when('guardian[value]', {
    is: true,
    then: Yup.string()
      .min(6, 'Mínimo de 6 caracteres')
      .required('Campo obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  birthDateGuardian: birthDateGuardianSchemas,
  birthDateGuardianInvalid: Yup.bool().oneOf([false], 'Campo inválido'),
  phoneNumber: Yup.object().shape({
    ddd: Yup.string(),
    ddi: Yup.string(),
    phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
      return value ? !Utils.allEqual(value) : true;
    }),
    completeNumber: Yup.string().test(
      'valid-celular',
      'Campo inválido',
      value => {
        if (value && value.length > 2) {
          const isValid = isPossiblePhoneNumber(`+${value}`);
          if (isValid) return true;
          return false;
        }
        return true;
      }
    ),
  }),

  phoneGuardian: Yup.object().shape({
    ddd: Yup.string(),
    ddi: Yup.string(),
    phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
      return value ? !Utils.allEqual(value) : true;
    }),
    completeNumber: Yup.string().test(
      'valid-celular',
      'Campo inválido',
      value => {
        if (value && value.length > 2) {
          const isValid = isPossiblePhoneNumber(`+${value}`);
          if (isValid) return true;
          return false;
        }
        return true;
      }
    ),
  }),
  email: Yup.string('Campo inválido').email('Campo inválido').notRequired(),
  emailGuardian: Yup.string('Campo inválido')
    .email('Campo inválido')
    .notRequired(),
});

export const SchemaInitialValues = (docSelected, typeDocumentSelected) => {
  const initialValues = {
    name: '',
    birthDate: '',
    birthDateInvalid: false,
    birthDateGuardianInvalid: false,
    doc: docSelected ?? '',
    phoneNumber: {
      ddd: '',
      ddi: '',
      phone: '',
      completeNumber: '',
    },
    email: '',
    docGuardian: '',
    nameGuardian: '',
    birthDateGuardian: '',
    cepResponsible: '',
    districtReponsible: '',
    publicPlaceReponsible: '',
    numberReponsible: '',
    complementReponsible: '',
    phoneGuardian: {
      ddi: '55',
      ddd: '',
      phone: '',
      completeNumber: '',
    },
    emailGuardian: '',
    gender: {
      label: null,
      value: null,
    },
    typeDocument: typeDocumentSelected ?? {
      label: 'CPF',
      value: 'cpf',
      selected: 0,
    },
    guardianCode: null,
    guardian: {
      label: 'Não',
      value: false,
    },
    typeDocumentGuardian: {
      label: '',
      value: '',
      selected: 0,
    },
  };
  return initialValues;
};
