import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 4.62rem 15rem 0rem 15rem;
  display: flex;
  gap: 24px;
  justify-content: center;
  @media ${Utils.device.laptop} {
    overflow-y: hidden;
    padding: 2rem 1.5rem 0rem 1.5rem;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
`;
export const WarningWrapper = styled.div`
  padding-right: 15rem;
  padding-left: 15rem;
  padding-bottom: 4.12rem;
  margin-top: 16px;
  @media ${Utils.device.laptop} {
    max-width: 382px;
    margin: 8px auto 0px auto;
    padding: 0rem 1.5rem 2rem 1.5rem;
  }
`;
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 482px;
  .tmp-typography__heading--large {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    letter-spacing: -0.01em;
    color: #292929;
    max-width: 350px;
  }
  .tmp-typography__paragraph--small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #666666;
    max-width: 381px;
    strong {
      font-weight: 600;
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  gap: 12px;
  .tmp-link {
    max-width: max-content;
  }
`;

export const DeviceController = styled.div`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'inherit')};
  @media ${Utils.device.laptop} {
    display: ${({ isMobile }) => (isMobile ? 'inherit' : 'none')};
  }
`;

export const QRCodeContainer = styled.div`
  @media ${Utils.device.laptop} {
    display: none;
  }

  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: ${({ isQRCodeRoute }) => (isQRCodeRoute ? '12px' : '35px')};
  margin-bottom: ${({ isQRCodeRoute }) => (isQRCodeRoute ? '-12px' : '0px')};
  canvas {
    padding: 9.76px;
  }
  .tmp-typography__paragraph--small {
    width: 179px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
`;

export const ListWrapper = styled.div`
  max-width: 100vw;

  .tmp-list-item {
    height: auto;
    padding: 20px 24px;
    color: #292929;
    span {
      line-height: 150%;
    }
  }
  .tmp-list-item:hover,
  .tmp-list-item:focus,
  .tmp-list-item:focus-visible {
    background-color: #fff;
    font-weight: inherit;
    font-size: inherit;
    color: #292929;
    cursor: inherit;
    outline: none;
    outline-offset: none;
    cursor: inherit;
  }
  .tmp-list-item__leading-icon {
    padding-right: 18px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const InformationActions = styled.div`
  display: flex;
  margin: 1.75rem 0rem;
  justify-content: flex-end;
  gap: 0.5rem;
  button {
    min-width: auto;
  }

  @media ${Utils.device.laptop} {
    flex-direction: column;
  }
`;
