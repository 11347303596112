import React, { useEffect, useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPatient } from 'services/patient.service';
import * as Yup from 'yup';

import * as S from './styles';

export function PatientIdentification() {
  const navigate = useNavigate();
  const params = useLocation();
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const typeDocumentSelected = params.state?.typeDocumentSelected;
  const docSelected = params.state?.docSelected;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doc: docSelected ?? '',
      typeDocument: typeDocumentSelected ?? {
        label: 'CPF',
        value: 'cpf',
        selected: 0,
      },
    },
    validationSchema: Yup.object().shape({
      doc: Yup.string()
        .when('typeDocument[value]', {
          is: 'rne',
          then: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Campo obrigatório'),
          otherwise: Yup.string(),
        })
        .when('typeDocument[value]', {
          is: 'passport',
          then: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Campo obrigatório'),
          otherwise: Yup.string(),
        })
        .when('typeDocument[value]', {
          is: 'cpf',
          then: Yup.string()
            .transform(value => value.replace(/[^\d]/g, ''))
            .required('Campo obrigatório')
            .min(11, 'CPF inválido')
            .max(11, 'CPF inválido')
            .test('is-cpf', 'CPF inválido', value =>
              Utils.validateCPF(Utils.removeMask('cpf', value))
            ),
          otherwise: Yup.string(),
        })
        .nullable(),
    }),
    onSubmit: values => {
      setLoadingButton(true);
      getPatient(
        values.typeDocument.value,
        Utils.removeMask(values.typeDocument.value, values.doc)
      )
        .then(response => {
          if (response) {
            navigate('/tipo-de-atendimento', {
              state: {
                patient: response,
                typeDocumentSelected: values.typeDocument,
                docSelected: values.doc,
              },
            });
          }
        })
        .catch(({ response }) => {
          const parseError = JSON.parse(response?.data?.errorMessage);
          if (parseError?.errorCode) {
            setOpenedDrawer(true);
          }
        })
        .finally(() => setLoadingButton(false));
    },
  });

  function handleCancel() {
    navigate('/');
  }

  function actionsContainer() {
    return (
      <S.ButtonContainer>
        <form onSubmit={formik.handleSubmit} className="formButton">
          <div data-testid="button-cancel">
            <D.TmpButton
              size="lg"
              type="secondary"
              handleClick={() => handleCancel()}
            >
              Cancelar
            </D.TmpButton>
          </div>
          <div data-testid="button-continue">
            <D.TmpButton
              size="lg"
              type="primary"
              typeButton="submit"
              loading={loadingButton}
            >
              Continuar
            </D.TmpButton>
          </div>
        </form>
      </S.ButtonContainer>
    );
  }

  useEffect(() => {
    if (docSelected && typeDocumentSelected) {
      formik.setFieldValue('typeDocument', typeDocumentSelected);
      formik.setFieldValue('doc', docSelected);
    }
  }, []);

  useEffect(() => {
    Utils.removeCharSpecial('#doc');
  }, [formik.values.doc]);

  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Layout>
        <S.Wrapper>
          <S.ImageContainer>
            <S.ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}downloadedExam.png`}
            />
          </S.ImageContainer>
          <S.WrapperContent>
            <S.TypographyContainer>
              <D.TmpTypography component="heading" size="large">
                Dados do paciente
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                Para iniciarmos, preencha os campos abaixo com as informações de
                identificação do paciente.
              </D.TmpTypography>
            </S.TypographyContainer>
            <form onSubmit={formik.handleSubmit}>
              <S.InputContainer>
                <S.SelectContent>
                  <D.TmpSelect
                    id="typeDocument"
                    name="typeDocument"
                    label="Documento"
                    placeholder="Escolha seu documento"
                    listItems={[
                      {
                        label: 'CPF',
                        value: 'cpf',
                      },
                      {
                        label: 'RNE',
                        value: 'rne',
                      },
                      {
                        label: 'Passaporte',
                        value: 'passport',
                      },
                    ]}
                    value={formik.values.typeDocument}
                    handleChange={value => {
                      formik.setFieldValue('typeDocument', value);
                      formik.setFieldTouched('doc', false);
                      formik.setFieldValue('doc', '');
                    }}
                  />
                </S.SelectContent>
                <S.InputContent>
                  <D.TmpInput
                    id="doc"
                    name="doc"
                    label="Nº do documento"
                    placeholder="Digite o Nº do seu documento"
                    value={formik.values.doc}
                    mask={
                      formik.values.typeDocument.value === 'cpf' ? 'cpf' : null
                    }
                    helperIcon={
                      formik.touched.doc && formik.errors.doc && <Erro />
                    }
                    helperText={formik.touched.doc && formik.errors.doc}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.doc &&
                      formik.errors.doc &&
                      formik.errors.doc
                    }
                  />
                </S.InputContent>
              </S.InputContainer>
            </form>
          </S.WrapperContent>
          <D.TmpDrawerContent
            title="Paciente não identificado"
            label="Para continuar o atendimento, cadastre os dados do paciente."
            buttonLabelPrimary="Cadastrar Novo Paciente"
            buttonLabelSecondary="Fechar"
            handleButtonClickPrimary={() => {
              setOpenedDrawer(false);
              handleCancel();
              navigate('/cadastro-de-paciente', {
                state: {
                  typeDocumentSelected: formik.values.typeDocument,
                  docSelected: formik.values.doc,
                },
              });
            }}
            handleClose={() => {
              setOpenedDrawer(false);
            }}
            isOpened={openedDrawer}
          />
        </S.Wrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
