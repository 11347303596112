import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 5rem 16rem 5rem 4rem;
  @media ${Utils.device.laptop} {
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const ImageContainer = styled.div``;

export const ImageView = styled.img`
  @media ${Utils.device.laptop} {
    display: none;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 480px;
  max-width: 390px;
  gap: 2rem;
  @media ${Utils.device.laptop} {
    margin-top: 0.5rem;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  > div {
    height: 120px;
  }
`;

export const SelectContent = styled.div`
  button {
    min-width: none;
    width: 100%;
  }
`;

export const InputContent = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5rem;
  .formButton {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-width: 850px;
    min-width: 772px;
    padding: 0 16rem;
  }
  button {
    height: 56px;
    min-width: 0;
    width: 140px;
  }
  @media ${Utils.device.laptop} {
    width: 100%;
    gap: 8px;
    margin-top: 0;
    .formButton {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 390px;
      min-width: 312px;
      padding: 0 1.5rem;
    }
    button {
      width: 100%;
    }
  }
`;
