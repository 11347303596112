// import { useEffect } from 'react';
import { Hooks, Utils } from '@b2b/core';

import { getHealthProfessional } from './enums';

export function PersonalData() {
  const { getUser } = Hooks.useUser();
  const {
    instituteCode,
    code,
    name,
    email,
    cpf,
    rne,
    passport,
    birthDate,
    accessToken,
    phoneNumber,
    ddiPhoneNumber,
    dddPhoneNumber,
    status,
    statusDescription,
    gender,
    healthProfessional,
    jobDescription,
  } = getUser() || null;
  const { document, typeDocument } = Utils.completeDocument(getUser());
  const documentFormatted =
    typeDocument === 'CPF' ? Utils.formatCPF(document) : document;

  const getPersonalData = [
    {
      class: 'personalDataLabelName',
      type: 'heading',
      content: 'Nome',
    },
    {
      class: 'personalDataName',
      editClass: 'editPersonalDataName',
      name: 'name',
      label: 'Nome',
      placeholder: 'Digite seu nome',
      type: 'paragraph',
      content: Utils.capitalize(name),
      capitalize: true,
    },
    {
      class: 'personalLabelStatus',
      type: 'heading',
      content: 'Status',
    },
    {
      class: 'personalDataStatus',
      editClass: 'editPersonalDataStatus',
      name: 'statusDescription',
      label: 'Status',
      placeholder: 'Selecione o status',
      type: 'paragraph',
      content: statusDescription,
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'personalLabelGender',
      type: 'heading',
      content: 'Sexo',
    },
    {
      class: 'personalDataGender',
      editClass: 'editPersonalDataGender',
      name: 'gender',
      label: 'Sexo',
      placeholder: 'Selecione o sexo',
      type: 'paragraph',
      content: gender === 'M' ? 'Masculino' : 'Feminino',
      typeInput: 'select',
      listItem: [
        {
          label: 'Masculino',
          value: 'M',
        },
        {
          label: 'Feminino',
          value: 'F',
        },
      ],
    },
    {
      class: 'personalLabelDocument',
      type: 'heading',
      content: 'Documento',
    },
    {
      class: 'personalDataDocument',
      editClass: 'editPersonalDataDocument',
      name: 'typeDocument',
      label: 'Documento',
      placeholder: 'Selecione o documento',
      type: 'paragraph',
      content: typeDocument,
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'personalLabelNumberDocument',
      type: 'heading',
      content: 'Nº do documento',
    },
    {
      class: 'personalDataNumberDocument',
      editClass: 'editPersonalDataNumberDocument',
      type: 'paragraph',
      label: 'Nº do documento',
      placeholder: 'Digite o número do documento',
      disabled: true,
      content: documentFormatted,
      name: 'document',
      mask: typeDocument === 'CPF' && 'cpf',
    },
    {
      class: 'personalDataLabelPhone',
      type: 'heading',
      content: 'Celular',
    },
    {
      class: 'personalDataPhone',
      editClass: 'editPersonalDataPhone',
      type: 'paragraph',
      content: Utils.formatCellphone(
        `${ddiPhoneNumber}${dddPhoneNumber}${phoneNumber}`
      ),
      label: 'Celular',
      placeholder: '+55 (00) 00000-0000',
      name: 'phoneNumber',
      mask: 'ddiphone',
    },
    {
      class: 'personalDataLabelEmail',
      type: 'heading',
      content: 'E-mail',
    },
    {
      class: 'personalDataEmail',
      editClass: 'editPersonalDataEmail',
      type: 'paragraph',
      content: email,
      label: 'E-mail',
      placeholder: 'Digite o e-mail',
      name: 'email',
    },
    {
      class: 'personalDataLabelJobDescription',
      type: 'heading',
      content: 'É profissional de saúde?',
    },
    {
      class: 'personalDataJobDescription',
      editClass: 'editPersonalDataJobDescription',
      type: 'paragraph',
      content: healthProfessional
        ? getHealthProfessional(jobDescription)
        : 'Não',
      label: 'É profissional de saúde?',
      placeholder: 'Selecione',
      name: 'healthProfessional',
      disabled: true,
      typeInput: 'select',
    },
  ];
  return {
    getPersonalData,
    instituteCode,
    code,
    name,
    cpf,
    rne,
    passport,
    birthDate,
    accessToken,
    document,
    phoneNumber,
    ddiPhoneNumber,
    dddPhoneNumber,
    typeDocument,
    email,
    status,
    gender,
    statusDescription,
    healthProfessional,
    jobDescription,
  };
}
