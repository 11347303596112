import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(44, 110, 242, 0.02);
  width: 100%;
  min-height: calc(100vh - 89px);
  @media ${Utils.device.laptop} {
    display: inherit;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1.75rem 5.5rem 3.75rem;
  width: 100%;
  max-width: 1366px;
  box-sizing: border-box;
  .containerBreadcrumbs {
    padding: 0 0.5rem;
  }
  @media ${Utils.device.laptop} {
    padding: 3rem 0;
    max-width: 390px;
    justify-content: center;
    form {
      width: 100%;
    }
    .containerBreadcrumbs {
      padding: 0 1.5rem;
    }
  }
`;

export const WrapperTypography = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  .tmp-typography__heading--medium {
    color: #231f20;
    font-weight: 700;
    font-size: 24px;
    margin-left: 0.5rem;
  }
  svg {
    path {
      fill: ${({ editType }) => (editType ? 'rgb(0, 0, 0)' : '#2C6EF2')};
    }
  }
  @media ${Utils.device.laptop} {
    justify-content: space-between;
    margin: 0 1rem;
    width: 95%;
  }
`;

export const WrapperCardShape = styled.div`
  .cardShape {
    grid-column: 1 / 13;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    > div {
      border: 0;
      padding: 0;
    }
    @media ${Utils.device.laptop} {
      > div {
        padding: 32px 0;
      }
    }
    .input-wrapper {
      min-width: auto;
      input:disabled {
        color: rgba(0, 0, 0, 0.32);
      }
    }
  }
  @media ${Utils.device.laptop} {
    width: 100%;
  }
`;

export const WrapperPersonalData = styled.section`
  padding: 2.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem 1.5rem;
  /* realizando ajuste no DS, pode apagar esta parte de estilo */
  .formControlForPhoneDisabled input:disabled,
  .formControlForInputDisabled input:disabled,
  .formControlForSelectDisabled div:nth-child(2) > button {
    color: #292929 !important;
  }
  .formControlForInputDisabled label,
  .formControlForSelectDisabled .tmp-select__label {
    color: #292929 !important;
  }
  /*até aqui */
  .tmp-typography__heading--small {
    color: #231f20;
    font-size: 16px;
  }
  .personalDataTitle {
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 18px;
      color: #231f20;
    }
  }
  .personalDataLabelName {
    grid-row: 2;
    grid-column: 1 / 8;
  }
  .personalDataName {
    grid-row: 3;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editPersonalDataName {
    grid-row: 3;
    grid-column: 1 / 8;
    > div {
      height: 120px;
    }
  }
  .personalLabelStatus {
    grid-row: 2;
    grid-column: 8 / 13;
  }
  .personalDataStatus {
    grid-row: 3;
    grid-column: 8 / 13;
  }
  .editPersonalDataStatus {
    grid-row: 3;
    grid-column: 8 / 13;
    button {
      min-width: auto;
      width: 100%;
    }
    > div {
      height: 120px;
    }
  }
  .personalLabelGender {
    grid-row: 4;
    grid-column: 1 / 4;
  }
  .personalDataGender {
    grid-row: 5;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editPersonalDataGender {
    grid-row: 5;
    grid-column: 1 / 4;
    button {
      min-width: auto;
      width: 100%;
    }
    > div {
      height: 120px;
    }
  }
  .personalLabelDocument {
    grid-row: 4;
    grid-column: 4 / 8;
  }
  .personalDataDocument {
    grid-row: 5;
    grid-column: 4 / 8;
  }
  .editPersonalDataDocument {
    grid-row: 5;
    grid-column: 4 / 8;
    button {
      min-width: auto;
      width: 100%;
    }
    > div {
      height: 120px;
    }
  }
  .personalLabelNumberDocument {
    grid-row: 4;
    grid-column: 8 / 13;
  }
  .personalDataNumberDocument {
    grid-row: 5;
    grid-column: 8 / 13;
  }
  .editPersonalDataNumberDocument {
    grid-row: 5;
    grid-column: 8 / 13;
    > div {
      height: 120px;
    }
  }
  .personalDataLabelPhone {
    grid-row: 6;
    grid-column: 1 / 4;
  }
  .personalDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
  }
  .editPersonalDataPhone {
    grid-row: 7;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
  }
  .personalDataLabelEmail {
    grid-row: 6;
    grid-column: 4 / 8;
  }
  .personalDataEmail {
    grid-row: 7;
    grid-column: 4 / 8;
  }
  .editPersonalDataEmail {
    grid-row: 7;
    grid-column: 4 / 8;
    > div {
      height: 120px;
    }
  }
  .personalDataLabelJobDescription {
    grid-row: 6;
    grid-column: 8 / 13;
  }
  .personalDataJobDescription {
    grid-row: 7;
    grid-column: 8 / 13;
  }
  .editPersonalDataJobDescription {
    grid-row: 7;
    grid-column: 8 / 13;
    button {
      min-width: auto;
      width: 100%;
    }
    > div {
      height: 120px;
    }
  }
  @media ${Utils.device.laptop} {
    padding: 0 1.5rem 2.5rem 1.5rem;
    gap: 0.5rem 0;
    .personalDataTitle {
      grid-column: 1 / 13;
      margin-bottom: 1rem;
    }
    .personalDataLabelName {
      grid-row: 2;
      grid-column: 1 / 13;
    }
    .personalDataName {
      grid-row: 3;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataName {
      grid-row: 3;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
    }
    .personalLabelStatus {
      grid-row: 4;
      grid-column: 1 / 13;
    }
    .personalDataStatus {
      grid-row: 5;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataStatus {
      grid-row: 5;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .personalLabelGender {
      grid-row: 6;
      grid-column: 1 / 13;
    }
    .personalDataGender {
      grid-row: 7;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataGender {
      grid-row: 7;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .personalLabelDocument {
      grid-row: 8;
      grid-column: 1 / 13;
    }
    .personalDataDocument {
      grid-row: 9;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataDocument {
      grid-row: 9;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .personalLabelNumberDocument {
      grid-row: 10;
      grid-column: 1 / 13;
    }
    .personalDataNumberDocument {
      grid-row: 11;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataNumberDocument {
      grid-row: 11;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
    }
    .personalDataLabelPhone {
      grid-row: 12;
      grid-column: 1 / 13;
    }
    .personalDataPhone {
      grid-row: 13;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataPhone {
      grid-row: 13;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
    }
    .personalDataLabelEmail {
      grid-row: 14;
      grid-column: 1 / 13;
    }
    .personalDataEmail {
      grid-row: 15;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editPersonalDataEmail {
      grid-row: 15;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
    }
    .personalDataLabelJobDescription {
      grid-row: 16;
      grid-column: 1 / 13;
    }
    .personalDataJobDescription {
      grid-row: 17;
      grid-column: 1 / 13;
    }
    .editPersonalDataJobDescription {
      grid-row: 17;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
    }
  }
`;

export const WrapperInstitutionData = styled.section`
  padding: 2.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem 1.5rem;
  /* realizando ajuste no DS, pode apagar esta parte de estilo */
  .formControlForPhoneDisabled input:disabled,
  .formControlForInputDisabled input:disabled,
  .formControlForSelectDisabled div:nth-child(2) > button {
    color: #292929 !important;
  }
  .formControlForPhoneDisabled label,
  .formControlForInputDisabled label,
  .formControlForSelectDisabled .tmp-select__label {
    color: #292929 !important;
  }
  /*até aqui */
  .loadingFeedback {
    grid-column: 6 / 8;
  }
  .tmp-typography__heading--small {
    color: #231f20;
    font-size: 16px;
  }
  .institutionDataTitle {
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
    h1 {
      font-size: 18px;
      color: #231f20;
    }
  }
  .instituteDataLabelCorporateName {
    grid-row: 2;
    grid-column: 1 / 13;
  }
  .institutionDataCorporateName {
    grid-row: 3;
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCorporateName {
    grid-row: 3;
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
  }
  .institutionDataLabelName {
    grid-row: 4;
    grid-column: 1 / 8;
  }
  .institutionDataName {
    grid-row: 5;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataName {
    grid-row: 5;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .institutionDataLabelStatus {
    grid-row: 4;
    grid-column: 8 / 13;
  }
  .institutionDataStatus {
    grid-row: 5;
    grid-column: 8 / 13;
  }
  .editInstituteDataStatus {
    grid-row: 5;
    grid-column: 7 / 13;
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .institutionDataLabelDocument {
    grid-row: 6;
    grid-column: 1 / 4;
  }
  .institutionDataDocument {
    grid-row: 7;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .editInstituteDataDocument {
    grid-row: 7;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .institutionDataLabelNumberDocument {
    grid-row: 6;
    grid-column: 4 / 8;
    width: 100%;
    margin-left: 0;
  }
  .institutionDataNumberDocument {
    grid-row: 7;
    grid-column: 4 / 8;
    margin-left: 0;
  }
  .editInstituteDataNumberDocument {
    grid-row: 7;
    grid-column: 4 / 7;
    margin-left: 0;
  }
  .institutionDataLabelEmail {
    grid-row: 6;
    grid-column: 8 / 13;
  }
  .institutionDataEmail {
    grid-row: 7;
    grid-column: 8 / 13;
  }
  .editInstituteDataEmail {
    grid-row: 7;
    grid-column: 7 / 13;
  }
  /* .institutionDataLabelPhone {
    grid-row: 8;
    grid-column: 1 / 4;
  }
  .institutionDataPhone {
    grid-row: 9;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataPhone {
    grid-row: 9;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  } */
  .institutionDataLabelCelPhone {
    grid-row: 8;
    grid-column: 1 / 4;
  }
  .institutionDataCelPhone {
    grid-row: 9;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCelPhone {
    grid-row: 9;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .instituteDataLabelState {
    grid-row: 10;
    grid-column: 1 / 4;
  }
  .instituteDataState {
    grid-row: 11;
    grid-column: 1 / 4;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataState {
    grid-row: 11;
    grid-column: 1 / 4;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    div > div > ol {
      height: 180px;
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .institutionDataLabelCEP {
    grid-row: 8;
    grid-column: 4 / 7;
  }
  .institutionDataCEP {
    grid-row: 9;
    grid-column: 4 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCEP {
    grid-row: 9;
    grid-column: 4 / 7;
    margin-bottom: 1.5rem;
  }
  .institutionDataLabelCity {
    grid-row: 10;
    grid-column: 4 / 8;
  }
  .institutionDataCity {
    grid-row: 11;
    grid-column: 4 / 8;
    button {
      min-width: auto;
      width: 100%;
    }
  }
  .editInstituteDataCity {
    grid-row: 11;
    grid-column: 4 / 7;
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .institutionDataLabelDistrict {
    grid-row: 10;
    grid-column: 8 / 11;
  }
  .institutionDataDistrict {
    grid-row: 11;
    grid-column: 8 / 11;
  }
  .editInstituteDataNeighborhood {
    grid-row: 11;
    grid-column: 7 / 13;
  }
  .institutionDataLabelPublicPlace {
    grid-row: 12;
    grid-column: 1 / 8;
  }
  .institutionDataPublicPlace {
    grid-row: 13;
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataAddress {
    grid-row: 13;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .institutionDataLabelNumber {
    grid-row: 12;
    grid-column: 8 / 10;
  }
  .institutionDataNumber {
    grid-row: 13;
    grid-column: 8 / 10;
  }
  .editInstituteDataAddressNumber {
    grid-row: 13;
    grid-column: 7 / 9;
  }
  .institutionDataLabelComplement {
    grid-row: 12;
    grid-column: 10 / 13;
  }
  .institutionDataComplement {
    grid-row: 13;
    grid-column: 10 / 13;
  }
  .editInstituteDataComplement {
    grid-row: 13;
    grid-column: 9 / 13;
  }
  .instituteDataLabelConvention {
    grid-row: 14;
    grid-column: 1 / 7;
  }
  .instituteDataConvention {
    grid-row: 15;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataConvention {
    grid-row: 15;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelCategory {
    grid-row: 14;
    grid-column: 8 / 13;
  }
  .instituteDataCategory {
    grid-row: 15;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCategory {
    grid-row: 15;
    grid-column: 7 / 13;
    margin-bottom: 1.5rem;
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelPlan {
    grid-row: 16;
    grid-column: 1 / 7;
  }
  .instituteDataPlan {
    grid-row: 17;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataPlan {
    grid-row: 17;
    grid-column: 1 / 7;
    margin-bottom: 1.5rem;
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelCompanion {
    grid-row: 16;
    grid-column: 8 / 13;
  }
  .instituteDataCompanion {
    grid-row: 17;
    grid-column: 8 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataCompanion {
    grid-row: 17;
    grid-column: 7 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  .instituteDataLabelSpecialties {
    grid-row: 18;
    grid-column: 1 / 13;
  }
  .instituteDataSpecialties {
    grid-row: 19;
    grid-column: 1 / 13;
    margin-bottom: 1.5rem;
  }
  .editInstituteDataSpecialties {
    grid-row: 19;
    grid-column: 1 / 13;
    > div {
      height: 120px;
    }
    button {
      min-width: auto;
      width: 100%;
    }
    p {
      margin-left: 1.5rem;
    }
  }
  @media ${Utils.device.laptop} {
    padding: 0 1.5rem 2.5rem 1.5rem;
    gap: 0.5rem 0;

    .editInstituteDataState {
      grid-row: 20;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      > div {
        height: auto;
      }
    }
    .instituteDataLabelState {
      grid-row: 20;
      grid-column: 1 / 4;
    }
    .instituteDataState {
      grid-row: 21;
      grid-column: 1 / 4;
      margin-bottom: 1.5rem;
    }
    .institutionDataTitle {
      grid-column: 1 / 13;
      margin: 2.5rem 0 1rem 0;
    }
    .instituteDataLabelCorporateName {
      grid-row: 2;
      grid-column: 1 / 13;
    }
    .institutionDataCorporateName {
      grid-row: 3;
      grid-column: 1 / 13;
      margin-bottom: 1.5rem;
    }
    .editInstituteDataCorporateName {
      grid-row: 3;
      grid-column: 1 / 13;
      margin-bottom: 1.5rem;
    }
    .institutionDataLabelName {
      grid-row: 4;
      grid-column: 1 / 13;
    }
    .institutionDataName {
      grid-row: 5;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataName {
      grid-row: 5;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelStatus {
      grid-row: 6;
      grid-column: 1 / 13;
    }
    .institutionDataStatus {
      grid-row: 7;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .editInstituteDataStatus {
      grid-row: 7;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .institutionDataLabelDocument {
      grid-row: 8;
      grid-column: 1 / 13;
    }
    .institutionDataDocument {
      grid-row: 9;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .editInstituteDataDocument {
      grid-row: 9;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
    }
    .institutionDataLabelNumberDocument {
      grid-row: 10;
      grid-column: 1 / 13;
      h1 {
        margin-left: 0;
      }
    }
    .institutionDataNumberDocument {
      grid-row: 11;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      p {
        margin-left: 0;
      }
    }
    .editInstituteDataNumberDocument {
      grid-row: 11;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      p {
        margin-left: 0;
      }
    }
    .institutionDataLabelEmail {
      grid-row: 12;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataEmail {
      grid-row: 13;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataEmail {
      grid-row: 13;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelPhone {
      grid-row: 14;
      grid-column: 1 / 13;
    }
    .institutionDataPhone {
      grid-row: 15;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataPhone {
      grid-row: 15;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelCelPhone {
      grid-row: 16;
      grid-column: 1 / 13;
      h1 {
        margin-left: 0;
      }
    }
    .institutionDataCelPhone {
      grid-row: 17;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      p {
        margin-left: 0;
      }
    }
    .editInstituteDataCelPhone {
      grid-row: 17;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      p {
        margin-left: 0;
      }
    }
    .institutionDataLabelCEP {
      grid-row: 18;
      grid-column: 1 / 13;
    }
    .institutionDataCEP {
      grid-row: 19;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataCEP {
      grid-row: 19;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelCity {
      grid-row: 22;
      grid-column: 1 / 13;
      h1 {
        margin-left: 0;
      }
    }
    .institutionDataCity {
      grid-row: 23;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 0;
      }
    }
    .editInstituteDataCity {
      grid-row: 21;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 0;
      }
    }
    .institutionDataLabelDistrict {
      grid-row: 24;
      grid-column: 1 / 13;
    }
    .institutionDataDistrict {
      grid-row: 25;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataNeighborhood {
      grid-row: 23;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelPublicPlace {
      grid-row: 26;
      grid-column: 1 / 13;
    }
    .institutionDataPublicPlace {
      grid-row: 27;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataAddress {
      grid-row: 25;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelNumber {
      grid-row: 28;
      grid-column: 1 / 13;
    }
    .institutionDataNumber {
      grid-row: 29;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataAddressNumber {
      grid-row: 27;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .institutionDataLabelComplement {
      grid-row: 30;
      grid-column: 1 / 13;
    }
    .institutionDataComplement {
      grid-row: 31;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataComplement {
      grid-row: 29;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .instituteDataLabelConvention {
      grid-row: 32;
      grid-column: 1 / 13;
    }
    .instituteDataConvention {
      grid-row: 33;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataConvention {
      grid-row: 31;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 1.5rem;
      }
    }
    .instituteDataLabelCategory {
      grid-row: 34;
      grid-column: 1 / 13;
    }
    .instituteDataCategory {
      grid-row: 35;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataCategory {
      grid-row: 33;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 1.5rem;
      }
    }
    .instituteDataLabelPlan {
      grid-row: 36;
      grid-column: 1 / 13;
    }
    .instituteDataPlan {
      grid-row: 37;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
    }
    .editInstituteDataPlan {
      grid-row: 35;
      grid-column: 1 / 13;
      margin-bottom: 0.5rem;
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 1.5rem;
      }
    }
    .instituteDataLabelCompanion {
      grid-row: 38;
      grid-column: 1 / 13;
    }
    .instituteDataCompanion {
      grid-row: 39;
      grid-column: 1 / 13;
      margin-bottom: 1.5rem;
    }
    .editInstituteDataCompanion {
      grid-row: 38;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 1.5rem;
      }
    }
    .instituteDataLabelSpecialties {
      grid-row: 40;
      grid-column: 1 / 13;
    }
    .instituteDataSpecialties {
      grid-row: 41;
      grid-column: 1 / 13;
      margin-bottom: 1.5rem;
    }
    .editInstituteDataSpecialties {
      grid-row: 40;
      grid-column: 1 / 13;
      > div {
        height: 120px;
      }
      button {
        min-width: auto;
        width: 100%;
      }
      p {
        margin-left: 1.5rem;
      }
    }
  }
`;

export const WrapperFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 2.5rem;
  justify-content: flex-end;
  button {
    min-width: auto;
  }
  @media ${Utils.device.laptop} {
    padding: 0 1.5rem;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;
