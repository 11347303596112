import { get, post } from './api_methods';

async function getGuardian(type, document) {
  const { data } = await get(`guardian?${type}=${document}`);
  return data.result;
}

async function postGuardian(params) {
  const { data } = await post(`guardian/register`, params);
  return data.result;
}

export { getGuardian, postGuardian };
