const getTitleDrawer = {
  skipNurseScreening: 'Você deseja passar pela triagem?',
  cancelService: 'Cancelar Atendimento',
  descriptionComplaint: 'Deseja mesmo excluir a queixa?',
};
const getLabelDrawer = {
  skipNurseScreening:
    'Você pode consultar um profissional de enfermagem antes do atendimento médico.',
  cancelService:
    'Deseja cancelar o atendimento? Ao cancelar, você retornará à tela de início.',
  descriptionComplaint:
    'Os dados da Queixa Descritiva serão excluídos definitivamente.',
};
const getButtonLabelPrimary = {
  skipNurseScreening: 'Sim, quero ir para a triagem',
  cancelService: 'Sim, quero cancelar atendimento',
  descriptionComplaint: 'Sim, quero excluir',
};
const getButtonLabelSecondary = {
  skipNurseScreening: 'Não',
  cancelService: 'Não, desejo permanecer',
  descriptionComplaint: 'Não quero excluir',
};
const initialValuesWithoutNurse = {
  specialties: {
    label: '',
    value: '',
  },
  descriptionComplaint: '',
};
export {
  getTitleDrawer,
  getLabelDrawer,
  getButtonLabelPrimary,
  getButtonLabelSecondary,
  initialValuesWithoutNurse,
};
