import React from 'react';

import * as D from '@tempo/tempo-design-system-core/dist';

import { Components } from '@b2b/core';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './styles';

export function EndingService() {
  const navigate = useNavigate();
  const params = useLocation();
  const { urlPatient, qrCode } = params.state;
  return (
    <Components.Layout>
      <S.Wrapper>
        <img
          src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}endingService.png`}
          alt="Obrigado por utilizar os nossos serviços"
        />
        <S.InformationWrapper>
          <D.TmpTypography component="heading" size="large">
            Obrigado por utilizar os nossos serviços
          </D.TmpTypography>
          <S.DeviceController isMobile={false}>
            {qrCode && (
              <S.QRCodeContainer isQRCodeRoute>
                <Components.QRCode url={urlPatient} />
                <D.TmpTypography component="paragraph" size="small">
                  Aponte seu dispositivo móvel para o QR Code ao lado.
                </D.TmpTypography>
              </S.QRCodeContainer>
            )}
          </S.DeviceController>
          {!qrCode && (
            <D.TmpTypography component="paragraph" size="small">
              Você será direcionado <strong>automaticamente</strong> à
              vídeochamada em uma nova aba do seu navegador.
            </D.TmpTypography>
          )}
          <S.LinkContainer>
            <D.TmpLink
              onClick={() => {
                window.open(urlPatient, '_blank');
              }}
              icon
            >
              {qrCode
                ? 'Ou acesse a vídeochamada via desktop'
                : 'Ir para vídeochamada'}
            </D.TmpLink>
            <D.TmpLink onClick={() => navigate('/')}>
              Retornar ao início
            </D.TmpLink>
            <S.DeviceController isMobile={false}>
              {!qrCode && (
                <S.QRCodeContainer>
                  <Components.QRCode url={urlPatient} />
                  <D.TmpTypography component="paragraph" size="small">
                    Você também pode acessar a chamada apontando um novo
                    dispositivo para o QR Code ao lado.
                  </D.TmpTypography>
                </S.QRCodeContainer>
              )}
            </S.DeviceController>
          </S.LinkContainer>
        </S.InformationWrapper>
      </S.Wrapper>
      <S.WarningWrapper>
        <Components.WarningAlert content="O link da vídeochamada fica disponível somente por 15 minutos." />
      </S.WarningWrapper>
    </Components.Layout>
  );
}
