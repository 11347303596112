export function getHealthProfessional(jobDescription) {
  const healthProfessionalType = {
    'médico(a)': 'Sim, médico(a)',
    'enfermeiro(a)': 'Sim, enfermeiro(a)',
    default: 'Não',
  };

  return (
    healthProfessionalType[jobDescription] || healthProfessionalType.default
  );
}
