import { useEffect, useRef, useState } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Services, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as Yup from 'yup';

import {
  getButtonLabelPrimary,
  getButtonLabelSecondary,
  getLabelDrawer,
  getTitleDrawer,
  initialValuesWithoutNurse,
} from './enums';
import * as S from './styles';

export function TypeService() {
  const useSpecialties = process.env.REACT_APP_USE_SPECIALTIES === 'true';
  const navigate = useNavigate();
  const specialtiesRef = useRef(null);
  const { addToast } = Hooks.useToast();
  const params = useLocation();
  const { addDrawer, removeDrawer } = Hooks.useDrawer();
  const [specialtiesLoaded, setSpecialtiesLoaded] = useState([]);
  const [loadingSpecialties, setLoadingSpecialties] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [descriptionComplaintValue, setDescriptionComplaintValue] =
    useState('');
  const [disabledPrimaryButtonModal, setDisabledPrimaryButtonModal] =
    useState(false);
  const { typeDocumentSelected, docSelected, patient } = params.state;
  const { getUser } = Hooks.useUser();
  const instituteCode = getUser()?.instituteCode.toString();

  const specialtiesSchemas = Yup.object().shape({
    label: Yup.string().required('Campo obrigatório').default(''),
    value: Yup.string().required('Campo obrigatório').default(''),
  });

  function ScrollToSpecialties() {
    setTimeout(() => {
      specialtiesRef.current.scrollIntoView();
    }, 100);
  }

  function handleSubmit(
    skipNurseScreening,
    specialtyCode,
    specialtyDescription
  ) {
    navigate('/acompanhante-digital', {
      state: {
        patient,
        descriptionComplaintValue,
        skipNurseScreening,
        typeDocumentSelected,
        docSelected,
        specialtyCode,
        specialtyDescription,
      },
    });
    removeDrawer();
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesWithoutNurse,
    validationSchema: Yup.object().shape({
      specialties: specialtiesSchemas,
    }),
    onSubmit: () => {
      // eslint-disable-next-line no-use-before-define
      dynamicDrawer('skipNurseScreening');
    },
  });

  function handleDescriptionComplaint(added = false, cancel = false) {
    if (
      added &&
      formik.values.descriptionComplaint !== descriptionComplaintValue &&
      formik.values.descriptionComplaint.length > 0
    ) {
      setOpenModal(false);
      setDescriptionComplaintValue(formik.values.descriptionComplaint);
    }
    if (
      cancel &&
      formik.values.descriptionComplaint !== descriptionComplaintValue
    ) {
      setOpenModal(false);
      formik.setFieldValue('descriptionComplaint', descriptionComplaintValue);
    }
    if (
      cancel &&
      formik.values.descriptionComplaint === descriptionComplaintValue
    ) {
      setOpenModal(false);
    }
  }

  function handleCancel() {
    Analytics.logEvent('Atendimento Cancelado', {
      Pagina: 'Seleção da especialidade',
      Cadastrado_Tasy: 'Não',
    });
    navigate('/');
  }

  function dynamicDrawer(type) {
    const specialtyCode = formik.values.specialties.value;
    const specialtyDescription = formik.values.specialties.label;
    const handleButtonClickPrimary = {
      skipNurseScreening: () => {
        handleSubmit(0, specialtyCode, specialtyDescription);
      },
      cancelService: () => {
        removeDrawer();
        handleCancel();
      },
      descriptionComplaint: () => {
        removeDrawer();
        formik.setFieldValue('descriptionComplaint', '');
        setDescriptionComplaintValue('');
        addToast({
          type: 'success',
          title: 'Queixa Descritiva excluída com sucesso',
          message: 'Os dados da queixa foram excluídos definitivamente.',
        });
      },
    };
    const handleButtonClickSecondary = {
      skipNurseScreening: () => {
        handleSubmit(1, specialtyCode, specialtyDescription);
      },
      cancelService: () => {
        removeDrawer();
        formik.setFieldTouched('specialties', false);
      },
      descriptionComplaint: () => {
        removeDrawer();
      },
    };

    addDrawer({
      title: getTitleDrawer[type],
      label: getLabelDrawer[type],
      buttonLabelPrimary: getButtonLabelPrimary[type],
      buttonLabelSecondary: getButtonLabelSecondary[type],
      handleButtonClickPrimary: handleButtonClickPrimary[type],
      handleButtonClickSecondary: handleButtonClickSecondary[type],
      closeOnClickOutside: true,
      closeButtonPrimaryOnClick: false,
      closeButtonSecondaryOnClick: false,
    });
  }

  useEffect(() => {
    setLoadingSpecialties(true);
    Services.getSpecialtiesInstitutes(instituteCode)
      .then(response => {
        const adaptSpecialties = [
          ...new Set(
            response
              .map(specialty => {
                return {
                  label: specialty.description,
                  value: specialty.specialtyCode,
                };
              })
              .sort((a, b) => {
                return a.label.localeCompare(b.label);
              })
          ),
        ];
        const foundedNurse = adaptSpecialties.find(item => item.value === 1);
        if (foundedNurse) {
          const nursePosition = adaptSpecialties.indexOf(foundedNurse);
          formik.setFieldValue('specialties', {
            ...foundedNurse,
            selected: nursePosition,
          });
        }
        setSpecialtiesLoaded(adaptSpecialties);
      })
      .catch(({ response }) => {
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => setLoadingSpecialties(false));
  }, []);

  useEffect(() => {
    if (formik.values.descriptionComplaint === descriptionComplaintValue) {
      setDisabledPrimaryButtonModal(true);
    } else if (formik.values.descriptionComplaint < 1) {
      setDisabledPrimaryButtonModal(true);
    } else {
      setDisabledPrimaryButtonModal(false);
    }
  }, [
    formik.values.descriptionComplaint === descriptionComplaintValue ||
      formik.values.descriptionComplaint.length < 1,
  ]);

  function handleChangeSelects(name, value) {
    formik.setFieldValue(name, value);
    specialtiesSchemas.isValid(value).then(valid => {
      formik.setFieldTouched(name, !valid);
    });
  }

  function dinamicJobContent() {
    return (
      <>
        <S.WrapperImage>
          <S.ImageView
            src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}serviceAvailable.png`}
          />
          {useSpecialties && (
            <S.WrapperTypography>
              <D.TmpTypography component="heading" size="large">
                Com quem deseja <br />
                realizar o atendimento?
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                Escolha o tipo de profissional para o atendimento.
              </D.TmpTypography>
            </S.WrapperTypography>
          )}
        </S.WrapperImage>
        <S.WrapperContent>
          <Components.PatientIdentificationCard
            patientName={patient?.name}
            showUpdate
            handleClick={() => {
              navigate('/identificacao-do-paciente', {
                state: {
                  typeDocumentSelected,
                  docSelected,
                },
              });
            }}
          />

          {useSpecialties ? (
            <>
              <S.WrapperTypography mobile>
                <D.TmpTypography component="heading" size="large">
                  Com quem deseja <br />
                  realizar o atendimento?
                </D.TmpTypography>
                <D.TmpTypography component="paragraph" size="small">
                  Escolha o tipo de profissional para o atendimento.
                </D.TmpTypography>
              </S.WrapperTypography>
              <S.WrapperSelect>
                <S.SelectSpecialties ref={specialtiesRef}>
                  <B.TmpSelect
                    loading={loadingSpecialties}
                    id="specialties"
                    name="specialties"
                    label="Especialidade"
                    placeholder="Selecione uma especialidade"
                    position="relative"
                    marginBottom="32px"
                    hideErrorActived
                    listItems={specialtiesLoaded}
                    value={formik.values.specialties}
                    handleChange={value =>
                      handleChangeSelects('specialties', value)
                    }
                    error={
                      formik.touched.specialties &&
                      formik.errors.specialties &&
                      formik.errors.specialties
                    }
                    helperIcon={
                      formik.touched.specialties &&
                      formik.errors.specialties && <Erro />
                    }
                    helperText={
                      formik.touched.specialties &&
                      formik.errors.specialties &&
                      formik.errors.specialties.value
                    }
                    onBlur={() => formik.setFieldTouched('specialties', true)}
                    onOpen={() => ScrollToSpecialties()}
                    disabled={loadingSpecialties}
                  />
                </S.SelectSpecialties>
              </S.WrapperSelect>
            </>
          ) : (
            <S.WrapperTypographyOnlyText>
              <D.TmpTypography component="heading" size="large">
                Quem realizará o atendimento?
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                No momento, nossos pacientes são direcionados para{' '}
                <strong>atendimento de enfermagem.</strong>
              </D.TmpTypography>
            </S.WrapperTypographyOnlyText>
          )}

          <S.WrapperDescriptiveComplaint hasSpecialties={useSpecialties}>
            {descriptionComplaintValue.length > 0 ? (
              <D.TmpShape>
                <div className="containerDescription">
                  <D.TmpLink onClick={() => setOpenModal(true)}>
                    <D.TmpIcon icon={<ReactSVG src={I.prescricao_guia} />} />
                    <D.TmpTypography component="caption" size="small">
                      Queixa Descritiva
                    </D.TmpTypography>
                  </D.TmpLink>
                </div>
                <D.TmpButtonIcon
                  icon={<ReactSVG src={I.excluir} />}
                  size="lg"
                  handleClick={() => dynamicDrawer('descriptionComplaint')}
                />
              </D.TmpShape>
            ) : (
              <>
                <D.TmpIcon icon={<ReactSVG src={I.mais} />} size="sm" />
                <D.TmpLink onClick={() => setOpenModal(true)}>
                  Inserir Queixa Descritiva (opcional)
                </D.TmpLink>
              </>
            )}
          </S.WrapperDescriptiveComplaint>
        </S.WrapperContent>
      </>
    );
  }

  function actionsContainer() {
    return (
      <S.WrapperButton
        useSpecialties={useSpecialties}
        descriptionComplaint={descriptionComplaintValue.length > 0}
      >
        <form onSubmit={formik.handleSubmit} className="formButton">
          <D.TmpButton
            size="lg"
            type="secondary"
            typeButton="button"
            handleClick={() => {
              formik.setFieldTouched('specialties', false);
              dynamicDrawer('cancelService');
            }}
          >
            Cancelar
          </D.TmpButton>
          <D.TmpButton
            size="lg"
            type="primary"
            typeButton="button"
            handleClick={() => formik.handleSubmit()}
          >
            Continuar
          </D.TmpButton>
        </form>
      </S.WrapperButton>
    );
  }

  return (
    <Components.LayoutSeparate
      actions={actionsContainer()}
      paddingBottomIOS="32px"
    >
      <Components.Layout>
        <S.Wrapper>
          {dinamicJobContent()}
          <S.ModalWrapper disabledButton={disabledPrimaryButtonModal}>
            <D.TmpModal
              isOpened={openModal}
              labelPrimary={
                descriptionComplaintValue.length ? 'Salvar' : 'Adicionar'
              }
              labelSecondary="Cancelar"
              handleConfirm={() => handleDescriptionComplaint(true, false)}
              handleCancel={() => handleDescriptionComplaint(false, true)}
              handleClose={() => handleDescriptionComplaint(false, true)}
              disabled
            >
              <S.ModalContent>
                <S.TitleModal>
                  <D.TmpTypography>
                    Qual(ais) a(s) queixas do paciente?
                  </D.TmpTypography>
                  <D.TmpTypography component="paragraph">
                    Preencha o campo abaixo.
                  </D.TmpTypography>
                </S.TitleModal>
                <D.TmpTextarea
                  label="Descrição (opcional)"
                  placeholder="Descreva as queixas do paciente"
                  maxLength={254}
                  id="descriptionComplaint"
                  value={formik.values.descriptionComplaint}
                  handleChange={formik.handleChange}
                />
              </S.ModalContent>
            </D.TmpModal>
          </S.ModalWrapper>
        </S.Wrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
