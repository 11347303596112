import { Utils } from '@b2b/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export function SchemasValidation(mandatoryDigitalCompanion) {
  if (mandatoryDigitalCompanion) {
    return Yup.object().shape({
      name: Yup.string()
        .min(6, 'Mínimo de 6 caracteres')
        .required('Campo obrigatório'),
      mobileCompanion: Yup.object().shape({
        ddd: Yup.string(),
        ddi: Yup.string(),
        phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
          return value ? !Utils.allEqual(value) : true;
        }),
        completeNumber: Yup.string()
          .test('required-cell', 'Campo obrigatório', value => {
            if (!value || value.length < 1) {
              return false;
            }
            return true;
          })
          .test('valid-celular', 'Campo inválido', value => {
            if (value && value.length > 2) {
              const isValid = isPossiblePhoneNumber(`+${value}`);
              if (isValid) return true;
              return false;
            }
            return false;
          })
          .required('Campo obrigatório'),
      }),
    });
  }
  return Yup.object().shape({
    name: Yup.string().min(6, 'Mínimo de 6 caracteres'),
    mobileCompanion: Yup.object().shape({
      ddd: Yup.string(),
      ddi: Yup.string(),
      phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
        return value ? !Utils.allEqual(value) : true;
      }),
      completeNumber: Yup.string()
        .test('required-cell', 'Campo obrigatório', value => {
          if (!value || value.length < 1) {
            return false;
          }
          return true;
        })
        .test('valid-celular', 'Campo inválido', value => {
          if (value && value.length > 2) {
            const isValid = isPossiblePhoneNumber(`+${value}`);
            if (isValid) return true;
            return false;
          }
          return true;
        }),
    }),
  });
}

export const schemaInitialValues = {
  name: '',
  mobileCompanion: {
    ddd: '',
    ddi: '',
    phone: '',
    completeNumber: '',
  },
};
