import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 5rem 16rem 5rem 7.5rem;
  @media ${Utils.device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const ImageContainer = styled.div`
  @media ${Utils.device.laptop} {
    display: flex;
    justify-content: center;
  }
`;

export const ImageView = styled.img`
  @media ${Utils.device.mobile} {
    max-height: 12.5rem;
    max-width: 12.5rem;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 480px;
  max-width: 390px;
  gap: 2rem;
  @media ${Utils.device.laptop} {
    margin: 0.5rem auto auto auto;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  .mandatoryConsultationCompanionLabel {
    font-size: 24px;
    font-weight: 400;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  > div {
    height: 120px;
  }
`;

export const InputContent = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5rem;
  .formButton {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-width: 850px;
    min-width: 772px;
    padding: 0 16rem;
  }
  button {
    height: 56px;
    min-width: 0;
  }
  @media ${Utils.device.laptop} {
    width: 100%;
    gap: 8px;
    margin-top: 0;
    .formButton {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 390px;
      min-width: 312px;
      padding: 0 1.5rem;
    }
    button {
      width: 100%;
    }
  }
`;
