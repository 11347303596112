import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 6rem 11.81rem 0rem 8.43rem;
  display: flex;
  justify-content: center;
  gap: 4rem;
  @media ${Utils.device.laptop} {
    gap: 0;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 2rem 1.5rem 0.5rem 1.5rem;
    img {
      max-width: 100%;
    }
  }
  @media ${Utils.device.onlyS8} {
    gap: 0;
    padding: 2rem 0rem;
  }
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  max-width: 480px;
  min-width: 450px;
  white-space: pre-line;
  h1,
  p {
    max-width: 460px;
    min-width: 312px;
  }
  @media ${Utils.device.laptop} {
    max-width: 390px;
    min-width: 312px;
  }
  @media ${Utils.device.onlyS8} {
    max-width: 330px;
    min-width: 330px;
    white-space: pre-line;
  }
`;
export const DeviceController = styled.div`
  display: ${({ isMobile }) => (isMobile ? 'inherit' : 'none')};
  @media ${Utils.device.desktopLarge} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'inherit')};
  }
`;
export const ListWrapper = styled.div`
  .tmp-list-item {
    height: auto;
    padding: 20px 24px;
    color: #292929;
    span {
      line-height: 150%;
    }
  }
  .tmp-list-item:hover,
  .tmp-list-item:focus,
  .tmp-list-item:focus-visible {
    background-color: #fff;
    font-weight: inherit;
    font-size: inherit;
    color: #292929;
    cursor: inherit;
    outline: none;
    outline-offset: none;
    cursor: inherit;
  }
  .tmp-list-item__leading-icon {
    padding-right: 18px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SoundNotification = styled.audio``;
const getMarginTopInformationActions = (skipNurseScreening, ready) => {
  if (!skipNurseScreening && !ready) return '-4.50rem';
  if (!skipNurseScreening && ready) return '-1.50rem';
  return '-4.50rem';
};
export const InformationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 930px;
  min-width: 772px;
  gap: 0.5rem;
  button {
    min-width: auto;
  }
  @media ${Utils.device.desktopLarge} {
    margin-top: ${({ skipNurseScreening, ready }) =>
      getMarginTopInformationActions(skipNurseScreening, ready)};
  }
  @media ${Utils.device.laptop} {
    width: 90%;
    min-width: 312px;
    flex-direction: column;
    max-width: 390px;
    margin-top: 0;
  }
`;

export const ModalSubtitleWrapper = styled.div`
  margin-top: 8px;
  @media ${Utils.device.tablet} {
    margin-bottom: 15px;
  }
`;

export const ModalListWrapper = styled.div`
  max-width: 100vw;
  ul {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 8px;
    margin-top: 2.5rem;
    li {
      > div span {
        font-weight: 400;
      }
    }
    @media ${Utils.device.tablet} {
      grid-template-columns: 1fr;
    }
  }
  .tmp-list-item {
    height: auto;
    padding: 20px 24px;
    color: #292929;
    span {
      line-height: 150%;
    }
  }
  .tmp-list-item:hover,
  .tmp-list-item:focus,
  .tmp-list-item:focus-visible {
    background-color: #fff;
    font-weight: inherit;
    font-size: inherit;
    color: #292929;
    cursor: inherit;
    outline: none;
    outline-offset: none;
    cursor: inherit;
  }
  .tmp-list-item__leading-icon {
    padding-right: 18px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const DoNotShowInstructions = styled.div`
  @media ${Utils.device.tablet} {
    padding: 16px 0px;
  }
`;

export const ModalWrapper = styled.div`
  z-index: 9999;
  > div > div {
    min-height: 500px;
    max-width: 785px;
  }
  @media ${Utils.device.tablet} {
    > div > div {
      height: 100vh;
    }
  }
  @media ${Utils.device.mobile} {
    > div > div {
      min-width: 312px;
      max-width: 480px;
    }
  }
`;
