/* eslint-disable no-console */
import React from 'react';

import { Analytics, Hooks } from '@b2b/core';
import propTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

export function PrivateRoutes({ children }) {
  Hooks.AuthVerify();
  Analytics.LogPageViewAnalytics('Responsável');
  const isLogged = !!localStorage.getItem('@HSL-B2B:token');

  return isLogged ? children : <Navigate to="/login" replace />;
}

PrivateRoutes.defaultProps = {
  children: null,
};

PrivateRoutes.propTypes = {
  children: propTypes.node,
};
