import React, { useEffect } from 'react';

import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getCode } from 'services/login.service';
import * as Yup from 'yup';

import * as S from './styles';

export function Login() {
  const navigate = useNavigate();
  const { addToast } = Hooks.useToast();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      doc: '',
      typeDocument: {
        label: 'CPF',
        value: 'cpf',
        selected: 0,
      },
    },
    validationSchema: Yup.object().shape({
      doc: Yup.string()
        .when('typeDocument[value]', {
          is: 'rne',
          then: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Campo obrigatório'),
          otherwise: Yup.string(),
        })
        .when('typeDocument[value]', {
          is: 'passport',
          then: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Campo obrigatório'),
          otherwise: Yup.string(),
        })
        .when('typeDocument[value]', {
          is: 'cpf',
          then: Yup.string()
            .transform(value => value.replace(/[^\d]/g, ''))
            .required('Campo obrigatório')
            .min(11, 'CPF inválido')
            .max(11, 'CPF inválido')
            .test('is-cpf', 'CPF inválido', value =>
              Utils.validateCPF(Utils.removeMask('cpf', value))
            ),
          otherwise: Yup.string(),
        })
        .nullable(),
    }),
    onSubmit: values => {
      setLoadingButton(true);
      getCode(
        values.typeDocument.value,
        Utils.removeMask('values.typeDocument.value', values.doc)
      )
        .then(response => {
          navigate('/codigo-de-acesso', {
            state: {
              responseCode: response.code,
              email: response.email,
              phone: response.phoneNumber,
              typeDocument: values.typeDocument.value,
              doc: Utils.removeMask(values.typeDocument.value, values.doc),
            },
          });
        })
        .catch(({ response }) => {
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => setLoadingButton(false));
    },
  });

  function actionContainer() {
    return (
      <S.WrapperButton data-testid="button-next">
        <form onSubmit={formik.handleSubmit} className="formButton">
          <D.TmpButton
            size="lg"
            type="primary"
            typeButton="submit"
            loading={loadingButton}
          >
            Próximo
          </D.TmpButton>
        </form>
      </S.WrapperButton>
    );
  }

  Hooks.ClearCache();

  useEffect(() => {
    Utils.removeCharSpecial('#doc');
  }, [formik.values.doc]);

  return (
    <Components.LayoutSeparate actions={actionContainer()}>
      <Components.Layout>
        <form>
          <S.Wrapper>
            <S.ImageContainer>
              <S.ImageView
                src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}doctorOffice.png`}
              />
            </S.ImageContainer>
            <S.WrapperContent>
              <S.TypographyContainer>
                <D.TmpTypography component="heading" size="large">
                  Olá! Vamos começar?
                </D.TmpTypography>
                <D.TmpTypography component="paragraph" size="small">
                  Selecione o tipo de documento e insira o número para acessar o
                  pronto atendimento digital.
                </D.TmpTypography>
              </S.TypographyContainer>
              <S.WrapperInput>
                <S.SelectContent>
                  <D.TmpSelect
                    id="typeDocument"
                    name="typeDocument"
                    label="Documento"
                    placeholder="Escolha seu documento"
                    listItems={[
                      {
                        label: 'CPF',
                        value: 'cpf',
                      },
                      {
                        label: 'RNE',
                        value: 'rne',
                      },
                      {
                        label: 'Passaporte',
                        value: 'passport',
                      },
                    ]}
                    value={formik.values.typeDocument}
                    handleChange={value => {
                      formik.setFieldValue('typeDocument', value);
                      formik.setFieldTouched('doc', false);
                      formik.setFieldValue('doc', '');
                    }}
                  />
                </S.SelectContent>
                <S.InputContent>
                  <D.TmpInput
                    id="doc"
                    name="doc"
                    label="Nº do documento"
                    placeholder="Digite o Nº do seu documento"
                    value={formik.values.doc}
                    mask={
                      formik.values.typeDocument.value === 'cpf' ? 'cpf' : null
                    }
                    helperIcon={
                      formik.touched.doc && formik.errors.doc && <Erro />
                    }
                    helperText={formik.touched.doc && formik.errors.doc}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.doc &&
                      formik.errors.doc &&
                      formik.errors.doc
                    }
                  />
                </S.InputContent>
              </S.WrapperInput>
            </S.WrapperContent>
          </S.Wrapper>
        </form>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
