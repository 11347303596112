import { useState } from 'react';

import { erro as Error } from '@tempo/tempo-assets/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Components, Hooks, Utils } from '@b2b/core';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInstitute } from 'services/institute.service';
import { getCode, validateCode } from 'services/login.service';

import {
  Helper,
  InformationActions,
  InformationWrapper,
  Wrapper,
} from './styles';

export function AccessCode() {
  const navigate = useNavigate();
  const params = useLocation();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const { saveToken, saveAccountType, setInstituteUser } = Hooks.useUser();
  const { addToast } = Hooks.useToast();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  function handleError(state, message) {
    setError(state);
    setErrorText(message);
  }

  function handleResend() {
    getCode(
      params.state.typeDocument,
      Utils.removeMask(params.state.typeDocument, params.state.doc)
    )
      .then(() => {})
      .catch(({ response }) => {
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      });
  }

  // eslint-disable-next-line no-unused-vars
  function handleGetInstitution(instituteCode) {
    getInstitute(instituteCode)
      .then(response => {
        setInstituteUser(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  function handleSubmit(value) {
    const code = value || otp;
    if (!Utils.RE_DIGIT.test(code) || code.length !== 4) {
      handleError(true, 'Campo inválido');
    } else if (code === 0 || code === null || code === undefined) {
      handleError(true, 'Campo obrigatório');
    } else {
      handleError(false);
      setLoadingButton(true);
      validateCode(params.state.doc, params.state.responseCode, code)
        .then(user => {
          saveToken(user.accessToken);
          const instituteCode = jwtDecode(user.accessToken)?.data
            ?.instituteCode;
          // eslint-disable-next-line no-console
          handleGetInstitution(instituteCode);
          saveAccountType('Conta do Responsável');
          navigate('/');
        })
        .catch(({ response }) => {
          setOtp('');
          const firstField = document.querySelector(`input[id=accessCode-0]`);
          firstField.focus();
          addToast({
            type: 'error',
            title: Utils.getError('title', response),
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => setLoadingButton(false));
    }
  }

  function actionsContainer() {
    return (
      <InformationActions>
        <div data-testid="button-exit">
          <D.TmpButton
            size="lg"
            type="secondary"
            handleClick={() => {
              navigate('/');
            }}
          >
            Sair
          </D.TmpButton>
        </div>
        <div data-testid="button-enter">
          <D.TmpButton
            size="lg"
            loading={loadingButton}
            handleClick={() => handleSubmit()}
          >
            Entrar
          </D.TmpButton>
        </div>
      </InformationActions>
    );
  }

  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Header titleSEO="Pronto Atendimento Digital" />
      <Wrapper>
        <img
          src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}lock.png`}
          alt="Código de Acesso"
        />
        <InformationWrapper>
          <D.TmpTypography component="heading" size="large">
            Código de Acesso
          </D.TmpTypography>
          <D.TmpTypography component="paragraph" size="small">
            Insira o código que enviamos para o e-mail{' '}
            {Utils.splitMask(params.state.email, '@')} e telefone{' '}
            {Utils.splitMask(params.state.phone, '-')}.
          </D.TmpTypography>
          <Components.ResendTimer
            initialMinute={3}
            onResend={() => {
              handleResend();
            }}
          />
          <div className="wrapperInputAccessCode">
            <Components.InputAccessCode
              id="otp"
              name="otp"
              value={otp}
              valueLength={4}
              onChange={e => setOtp(e)}
              handleSubmit={value => handleSubmit(value)}
              handleError={(state, message) => handleError(state, message)}
              error={error}
            />
          </div>
          {error && (
            <Helper>
              <span className="icon">
                <D.TmpIcon size="sm" icon={<Error />} />
              </span>
              {errorText}
            </Helper>
          )}
        </InformationWrapper>
      </Wrapper>
    </Components.LayoutSeparate>
  );
}
