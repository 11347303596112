import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  gap: 10rem;
  padding: 5rem 16rem 5rem 4rem;
  justify-content: center;
  align-items: center;
  @media ${Utils.device.laptop} {
    flex-direction: column;
    gap: 0;
    padding: 2rem 1.5rem;
  }
`;

export const ImageContainer = styled.div``;

export const ImageView = styled.img`
  @media ${Utils.device.laptop} {
    width: 80%;
    padding: 1.5rem;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 480px;
  max-width: 390px;
  min-width: 312px;
  gap: 3rem;
  color: #ffffff;
  @media ${Utils.device.laptop} {
    align-items: center;
    width: 100%;
    height: auto;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
  justify-content: space-between;
  height: 100px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 48px;
  width: 100%;
  max-width: 850px;
  min-width: auto;
  margin-right: -30px;
  margin-top: -210px;
  @media ${Utils.device.laptop} {
    margin-right: 0;
    margin-top: 0;
    width: 90%;
    max-width: 390px;
    min-width: 312px;
  }
  button {
    height: 48px;

    @media ${Utils.device.laptop} {
      width: 100%;
    }

    path {
      fill: #ffffff;
      transition: all ease 500ms;
    }
    &:hover {
      path {
        fill: #0a3a99;
      }
    }
  }
`;
