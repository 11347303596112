import { Analytics, Hooks, Pages } from '@b2b/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import * as P from 'pages';

import { NoAuthRoutes } from './noAuthRoutes';
import { PrivateRoutes } from './privateRoutes';

const routes = [
  { path: '/', element: <P.Home />, isPrivate: true },
  {
    path: '/identificacao-do-paciente',
    element: <P.PatientIdentification />,
    isPrivate: true,
  },
  { path: '/login', element: <P.Login />, isPrivate: false, noAuth: true },
  {
    path: '/codigo-de-acesso',
    element: <P.AccessCode />,
    isPrivate: false,
    noAuth: true,
  },
  {
    path: '/sala-de-video',
    element: <P.VideoRoom />,
    isPrivate: false,
    noAuth: true,
  },
  {
    path: '/sala-de-video/:hashURL',
    element: <P.VideoRoom />,
    isPrivate: false,
    noAuth: true,
  },
  {
    path: '/tipo-de-atendimento',
    element: <P.TypeService />,
    isPrivate: true,
  },
  {
    path: '/cadastro-de-paciente',
    element: <P.RegisterPatient />,
    isPrivate: true,
  },
  {
    path: '/sucesso-ao-cadastrar',
    element: <Pages.RegistrationSuccessful />,
    isPrivate: true,
  },
  {
    path: '/informacao-do-sistema',
    element: <Pages.InfoSystem />,
    isPrivate: false,
  },
  {
    path: '/manutencao',
    element: <Pages.Maintenance />,
    isPrivate: false,
  },
  {
    path: '/acompanhante-digital',
    element: <P.ConsultationCompanion />,
    isPrivate: true,
  },
  {
    path: '/fila-de-atendimento',
    element: <P.QueueService />,
    isPrivate: true,
  },
  {
    path: '/encerrando-atendimento',
    element: <P.EndingService />,
    isPrivate: true,
  },
  {
    path: '/meus-dados',
    element: <P.MyData />,
    isPrivate: true,
  },
];

function getElementType(route) {
  const { isPrivate, noAuth, element } = route;
  if (isPrivate) return <PrivateRoutes>{element}</PrivateRoutes>;
  if (noAuth) return <NoAuthRoutes>{element}</NoAuthRoutes>;
  return element;
}

export function Router() {
  Analytics.initializeGA();
  return (
    <BrowserRouter>
      <Hooks.ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Hooks.NoInternetConnection>
              <PrivateRoutes>
                <P.Home />
              </PrivateRoutes>
            </Hooks.NoInternetConnection>
          }
        />
        {routes.map((route, index) => (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            path={route.path}
            element={
              <Hooks.NoInternetConnection>
                {getElementType(route)}
              </Hooks.NoInternetConnection>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
