import { Hooks, Utils } from '@b2b/core';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import { getHealthProfessional } from './enums';

export const schemas = Yup.object().shape({
  name: Yup.string()
    .min(6, 'Mínimo de 6 caracteres')
    .required('Campo obrigatório'),
  gender: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  phoneNumber: Yup.object().shape({
    ddd: Yup.string(),
    ddi: Yup.string(),
    phone: Yup.string().test('valid-celular', 'Campo inválido', value => {
      return value ? !Utils.allEqual(value) : true;
    }),
    completeNumber: Yup.string()
      .test('required-cell', 'Campo obrigatório', value => {
        if (!value || value.length < 1) {
          return false;
        }
        return true;
      })
      .test('valid-celular', 'Campo inválido', value => {
        if (value) {
          const isValid = isPossiblePhoneNumber(`+${value}`);
          if (isValid) return true;
          return false;
        }
        return true;
      })
      .required('Campo obrigatório'),
  }),
  email: Yup.string('Campo inválido')
    .email('Campo inválido')
    .required('Campo obrigatório'),
});

export const SchemaInitialValues = (
  name,
  status,
  gender,
  typeDocument,
  statusDescription,
  document,
  phoneNumber,
  ddiPhoneNumber,
  dddPhoneNumber,
  email,
  healthProfessional,
  jobDescription,
  corporateName,
  fantasyName,
  statusInstitute,
  emailInstitute,
  phoneNumberInstitute,
  ddiPhoneNumberInstitute,
  dddPhoneNumberInstitute,
  cepInstitute,
  neighborhoodInstitute,
  addressInstitute,
  addressNumberInstitute,
  complementInstitute,
  stateInstitute,
  cityInstitute,
  hasCompanionRequired,
  documentInstitute,
  typeDocumentInstitute,
  convention,
  category,
  plan
) => {
  const { stateDescriptionByInitials } = Hooks.useStateList();
  const { specialties } = Hooks.useUser();
  const initialValues = {
    name: name ?? '',
    statusDescription: statusDescription
      ? {
          label: statusDescription,
          value: status,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    gender: gender
      ? {
          label: gender === 'M' ? 'Masculino' : 'Feminino',
          value: gender,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    typeDocument: typeDocument
      ? {
          label: typeDocument,
          value: typeDocument,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    healthProfessional: healthProfessional?.toString()
      ? {
          label: healthProfessional
            ? getHealthProfessional(jobDescription)
            : 'Não',
          value: healthProfessional,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    phoneNumber: {
      ddi: ddiPhoneNumber ?? '55',
      ddd: dddPhoneNumber ?? ' ',
      phone: phoneNumber ?? '',
    },
    document: document ?? '',
    email: email ?? '',
    corporateName: corporateName ?? '',
    fantasyName: fantasyName ?? '',
    statusInstitute: statusInstitute
      ? {
          label: statusInstitute === 'A' ? 'Ativo' : 'Inativo',
          value: statusInstitute,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    // documentInstitute: documentInstitute ?? '',
    emailInstitute: emailInstitute ?? '',
    phoneNumberInstitute: {
      ddi: ddiPhoneNumberInstitute ?? '55',
      ddd: dddPhoneNumberInstitute ?? ' ',
      phone: phoneNumberInstitute ?? '',
    },
    cepInstitute: cepInstitute ?? '',
    neighborhoodInstitute: neighborhoodInstitute ?? '',
    addressInstitute: addressInstitute ?? '',
    addressNumberInstitute: addressNumberInstitute ?? '',
    complementInstitute: complementInstitute ?? '',
    stateInstitute: stateInstitute
      ? {
          label: stateDescriptionByInitials(stateInstitute),
          value: stateInstitute,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    cityInstitute: cityInstitute
      ? {
          label: cityInstitute,
          value: cityInstitute,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    documentInstitute: documentInstitute ?? '',
    typeDocumentInstitute: typeDocumentInstitute
      ? {
          label: typeDocumentInstitute,
          value: typeDocumentInstitute.toLowerCase(),
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    convention: convention
      ? {
          label: convention,
          value: convention,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    category: category
      ? {
          label: category,
          value: category,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    plan: plan
      ? {
          label: plan,
          value: plan,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
    hasCompanionRequired: hasCompanionRequired?.toString()
      ? {
          label: hasCompanionRequired === 0 ? 'Não' : 'Sim',
          value: hasCompanionRequired,
          selected: hasCompanionRequired,
        }
      : {
          label: null,
          value: null,
          selected: '',
        },
    specialties: specialties
      ? {
          label: specialties,
          value: specialties,
          selected: 0,
        }
      : {
          label: null,
          value: null,
          selected: 0,
        },
  };
  return initialValues;
};
