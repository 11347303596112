import Erro from '@tempo/tempo-assets/dist/react/icons/erro';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVideoRoom } from 'services/patient.service';

import { SchemasValidation, schemaInitialValues } from './schemas';
import * as S from './styles';

export function ConsultationCompanion() {
  const navigate = useNavigate();
  const { loadingButton, setLoadingButton } = Hooks.useLoading();
  const { addToast } = Hooks.useToast();
  const { getUser, instituteUser } = Hooks.useUser();
  const { instituteCode } = getUser();
  const agentCode = getUser()?.code;
  const mandatoryConsultationCompanion =
    instituteUser.hasCompanionRequired !== 0;
  const params = useLocation();
  const {
    patient,
    descriptionComplaintValue,
    skipNurseScreening,
    typeDocumentSelected,
    docSelected,
    specialtyCode,
    specialtyDescription,
  } = params.state;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: schemaInitialValues,
    validationSchema: SchemasValidation(mandatoryConsultationCompanion),
    onSubmit: values => {
      setLoadingButton(true);
      getVideoRoom(
        patient?.code,
        agentCode,
        descriptionComplaintValue,
        instituteCode,
        skipNurseScreening,
        specialtyCode
      )
        .then(videoRoomData => {
          Analytics.logEvent('Atendimento Aberto', {
            Especialidade: specialtyDescription,
            Atendimento_Com_Triagem: skipNurseScreening ? 'Não' : 'Sim',
            Queixa_Descritiva_Preenchida: descriptionComplaintValue.length
              ? 'Sim'
              : 'Não',
            Acompanhante_Digital_Obrigatorio: mandatoryConsultationCompanion
              ? 'Sim'
              : 'Não',
          });
          navigate('/fila-de-atendimento', {
            state: {
              ...videoRoomData,
              patient,
              skipNurseScreening,
              typeDocumentSelected,
              docSelected,
              nameCompanion: values.name,
              mobileCompanion: values.mobileCompanion.completeNumber,
            },
          });
        })
        .catch(({ response }) => {
          Analytics.logEvent('Erro na abertura de atendimento', response);
          addToast({
            type: 'error',
            title: Utils.getError('title', response) || 'Erro desconhecido',
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
        })
        .finally(() => {
          setLoadingButton(false);
        });
    },
  });

  function handleCancel() {
    Analytics.logEvent('Atendimento Cancelado', {
      Pagina: 'Acompanhante Digital',
      Cadastrado_Tasy: 'Não',
    });
    navigate('/tipo-de-atendimento', {
      state: {
        patient,
        descriptionComplaintValue,
        skipNurseScreening,
        typeDocumentSelected,
        docSelected,
      },
    });
  }

  function actionsContainer() {
    return (
      <S.ButtonContainer>
        <form onSubmit={formik.handleSubmit} className="formButton">
          <div data-testid="button-cancel">
            <D.TmpButton
              size="lg"
              type="secondary"
              handleClick={() => handleCancel()}
            >
              Cancelar
            </D.TmpButton>
          </div>
          <div data-testid="button-continue">
            <D.TmpButton
              size="lg"
              type="primary"
              typeButton="submit"
              loading={loadingButton}
            >
              Ir para sala de espera
            </D.TmpButton>
          </div>
        </form>
      </S.ButtonContainer>
    );
  }

  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Layout>
        <S.Wrapper>
          <S.ImageContainer>
            <S.ImageView
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}healthPlan.png`}
            />
          </S.ImageContainer>
          <S.WrapperContent>
            <S.TypographyContainer>
              <D.TmpTypography component="heading" size="large">
                Acompanhante Digital na Consulta{' '}
                {!mandatoryConsultationCompanion && (
                  <span className="mandatoryConsultationCompanionLabel">
                    (opcional)
                  </span>
                )}
              </D.TmpTypography>
              <D.TmpTypography component="paragraph" size="small">
                Uma mensagem de Whatsapp será enviada para a pessoa com o link
                do atendimento. :)
              </D.TmpTypography>
            </S.TypographyContainer>
            <form onSubmit={formik.handleSubmit}>
              <S.InputContainer>
                <S.InputContent>
                  <D.TmpInput
                    id="name"
                    name="name"
                    label="Nome"
                    placeholder="Digite o nome"
                    value={formik.values.name}
                    helperIcon={
                      formik.touched.name && formik.errors.name && <Erro />
                    }
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.name &&
                      formik.errors.name &&
                      formik.errors.name
                    }
                    className="inputName"
                    capitalize
                  />
                </S.InputContent>
                <S.InputContent>
                  <DB2B.TmpPhoneInput
                    id="mobileCompanion"
                    name="mobileCompanion"
                    label="Celular"
                    value={formik.values.mobileCompanion}
                    getValue={value => {
                      if (value !== formik.values.mobileCompanion) {
                        formik.setFieldValue('mobileCompanion', value);
                      }
                    }}
                    defaultValue={`${formik.values.mobileCompanion.ddi}${formik.values.mobileCompanion.ddd}${formik.values.mobileCompanion.phone}`}
                    helperIcon={
                      formik.touched.mobileCompanion &&
                      formik.errors.mobileCompanion && <Erro />
                    }
                    helperText={
                      (formik.touched.mobileCompanion &&
                        formik.errors.mobileCompanion?.completeNumber) ||
                      (formik.touched.mobileCompanion &&
                        formik.errors.mobileCompanion?.phone)
                    }
                    error={
                      formik.touched.mobileCompanion &&
                      formik.errors.mobileCompanion &&
                      formik.errors.mobileCompanion
                    }
                    defaultCountry="BR"
                    className="inputPhone"
                    onBlur={formik.handleBlur}
                  />
                </S.InputContent>
              </S.InputContainer>
            </form>
          </S.WrapperContent>
        </S.Wrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
