/* eslint-disable react/prop-types */

/* eslint-disable react/no-unstable-nested-components */

/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';

import * as I from '@tempo/tempo-assets/dist/svgs/icons';
import * as DB2B from '@tempo/tempo-design-system-b2b/dist';
import * as D from '@tempo/tempo-design-system-core/dist';

import { Analytics, Components, Hooks, Utils } from '@b2b/core';
import notificationSound from '@b2b/core/src/assets/sounds/notification-sound.mp3';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { sendSMSVideoRoom } from 'services/patient.service';
import {
  leaveQueue,
  updateAttendanceStatus,
  useSocket,
} from 'services/queue.service';

import * as S from './styles';

export function QueueService() {
  const { addToast } = Hooks.useToast();
  const audioPlayer = useRef(null);
  const secondsForReesend = 60;
  const secondsForIntervalForSound =
    parseInt(process.env.REACT_APP_INTERVAL_SOUND_SECONDS, 10) * 1000;

  const params = useLocation();
  const {
    urlPatient,
    personCodeTasy,
    patient,
    skipNurseScreening,
    attendanceCode,
    typeDocumentSelected,
    docSelected,
    attendanceTasy,
    startQueueTime,
    nameCompanion,
    mobileCompanion,
  } = params.state;

  const [showInstructions, setShowInstructions] = useState(false);
  const [doNotShowInstructions, setDoNotShowInstructions] = useState(false);
  const [queue, setQueued] = useState(
    process.env.REACT_APP_SIMULATE_QUEUE === 'true' ? 30 : null
  );

  const { lastMessage, sendMessage, getWebSocket } = useSocket(personCodeTasy);
  const {
    loadingButtonPrimary,
    setLoadingButtonPrimary,
    setLoadingButtonSecondary,
  } = Hooks.useLoading();

  const navigate = useNavigate();

  function showModalInstructions() {
    const notShowInstructionsModal = localStorage.getItem(
      '@HSL-B2B:notShowInstructions'
    );
    if (!notShowInstructionsModal || notShowInstructionsModal === 'false') {
      setShowInstructions(true);
    }
  }
  const [ready, setReady] = useState(skipNurseScreening === 1);
  const [openedDrawer, setOpenedDrawer] = useState(false);

  function playSoundNotification() {
    audioPlayer?.current?.play();
    if (process.env.REACT_APP_USE_INTERVAL_SOUND === 'true') {
      setInterval(() => {
        audioPlayer?.current?.play();
      }, secondsForIntervalForSound);
    }
  }
  function handleSendSMS() {
    if (nameCompanion && mobileCompanion) {
      sendSMSVideoRoom(
        patient?.code,
        nameCompanion,
        mobileCompanion,
        urlPatient
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao disparar SMS',
            message: 'Erro ao enviar url da sala de vídeo por sms.',
          });
        });
    }
  }

  function queueSimulate() {
    if (queue > 0) {
      setTimeout(() => setQueued(queue - 1), 1000);
    }
    if (queue === 0 && !ready) {
      setReady(true);
      playSoundNotification();
    }
  }

  function labelQueue(mobile = false) {
    if (ready && skipNurseScreening)
      return 'Entre na sala e aguarde o profissional de saúde que irá atendê-lo(a)';

    if (ready && !skipNurseScreening)
      return mobile
        ? 'Chegou sua vez'
        : 'Você será direcionado automaticamente à vídeochamada em uma nova aba do seu navegador';

    if (!queue) return 'Aguarde';

    return `Você é o(a) ${queue}° da fila`;
  }
  function ListItem({
    icon,
    iconReady,
    showLabelQueue,
    label,
    labelReady,
    mobile,
  }) {
    return showLabelQueue ? (
      <D.TmpListItem
        handleClick={() => {}}
        label={labelQueue(mobile ?? false)}
        leadingIcon={
          ready ? <ReactSVG src={I[iconReady]} /> : <ReactSVG src={I[icon]} />
        }
      />
    ) : (
      <D.TmpListItem
        handleClick={() => {}}
        label={ready ? labelReady : label}
        leadingIcon={
          ready ? <ReactSVG src={I[iconReady]} /> : <ReactSVG src={I[icon]} />
        }
      />
    );
  }
  function handleQueue() {
    if (lastMessage) {
      if (lastMessage.data) {
        try {
          const QueuePosition = JSON.parse(lastMessage.data)?.POSICAO;
          setQueued(QueuePosition);
          if (QueuePosition === 0 && !ready) {
            handleSendSMS();
            setReady(true);
            sendMessage(`{"action": "disconnect"}`);
            getWebSocket().close();
            playSoundNotification();
          }
        } catch (e) {
          return false;
        }
      }
    }
    return false;
  }
  function handleUnderstand() {
    localStorage.setItem('@HSL-B2B:notShowInstructions', doNotShowInstructions);
  }

  function handleOpenVideoRoom(QRCodeAcess = false) {
    setLoadingButtonPrimary(true);
    updateAttendanceStatus(attendanceCode, 1)
      .then(() => {
        if (!skipNurseScreening) {
          Analytics.logEvent('Fila de Atendimento', {
            Tempo_Espera: Utils.getTimeQueue(startQueueTime),
          });
        }
        return true;
      })
      .catch(({ response }) => {
        addToast({
          type: 'error',
          title: Utils.getError('title', response),
          message: Utils.getError('message', response) || 'Erro desconhecido',
        });
      })
      .finally(() => {
        setLoadingButtonPrimary(false);
        if (QRCodeAcess) {
          navigate('/encerrando-atendimento', {
            state: { ...params.state, qrCode: true },
          });
        } else {
          window.open(urlPatient, '_blank');
          navigate('/encerrando-atendimento', {
            state: { ...params.state, qrCode: false },
          });
        }
      });
  }

  function handleLeave() {
    setLoadingButtonSecondary(true);
    try {
      sendMessage(`{"action": "disconnect"}`);
      getWebSocket().close();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      navigate('/');
      leaveQueue(personCodeTasy, attendanceTasy)
        .then(res => {
          if (res) {
            updateAttendanceStatus(attendanceCode, 3).then(() => {
              return true;
            });
            Analytics.logEvent('Atendimento Cancelado', {
              Pagina: 'Fila de Atendimento',
              Cadastrado_Tasy: 'Sim',
            });
          }
        })
        .catch(({ response }) => {
          addToast({
            type: 'error',
            title: Utils.getError('title', response) || 'Erro desconhecido',
            message: Utils.getError('message', response) || 'Erro desconhecido',
          });
          Analytics.logEvent('Erro ao cancelar atendimento', response);
        })
        .finally(() => {
          setLoadingButtonPrimary(false);
          setLoadingButtonSecondary(false);
        });
    }
  }
  function labelQRCode() {
    return (
      <>
        Você também pode acessar através de um novo dispositivo via{' '}
        <D.TmpLink onClick={() => handleOpenVideoRoom(true)}>QRCode</D.TmpLink>.
      </>
    );
  }

  function actionsContainer() {
    return (
      <S.InformationActions
        skipNurseScreening={skipNurseScreening}
        ready={ready}
      >
        <D.TmpButton
          size="lg"
          type="secondary"
          handleClick={() => {
            setOpenedDrawer(true);
          }}
        >
          Sair
        </D.TmpButton>
        <D.TmpButton
          size="lg"
          type="primary"
          typeButton="submit"
          loading={ready ? loadingButtonPrimary : false}
          disabled={!ready}
          handleClick={() => handleOpenVideoRoom()}
        >
          Entrar
        </D.TmpButton>
      </S.InformationActions>
    );
  }

  useEffect(() => {
    showModalInstructions();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SIMULATE_QUEUE === 'false') {
      handleQueue();
    }
  }, [lastMessage]);
  useEffect(() => {
    if (process.env.REACT_APP_SIMULATE_QUEUE === 'true') {
      queueSimulate();
    }
  }, [queue]);
  useEffect(() => {
    if (skipNurseScreening === 1) {
      handleSendSMS();
    }
    if (process.env.REACT_APP_SIMULATE_QUEUE === 'false' && !ready) {
      const sendDefaultMessageSocket = setInterval(() => {
        sendMessage(`{"action": "default"}`);
      }, secondsForReesend * 1000);
      return () => clearInterval(sendDefaultMessageSocket);
    }
    return true;
  }, []);

  function getLabelReady() {
    if (skipNurseScreening) {
      if (ready) {
        return 'Tudo pronto!\nSua sala está disponível!';
      }
      return 'O atendimento iniciará em breve!';
    }
    if (ready) {
      return 'Chegou sua vez!';
    }
    return 'O atendimento iniciará em breve!';
  }
  return (
    <Components.LayoutSeparate actions={actionsContainer()}>
      <Components.Layout>
        <S.Wrapper>
          <S.InformationWrapper>
            <img
              src={`${process.env.REACT_APP_S3_IMAGES_STORAGE}${
                ready ? `queueReady.png` : `queueService.png`
              }`}
              alt="Fila de Atendimento"
            />
            <D.TmpTypography component="heading" size="large">
              {getLabelReady()}
            </D.TmpTypography>
          </S.InformationWrapper>
          <S.InformationWrapper>
            <Components.PatientIdentificationCard
              patientName={patient?.name}
              showUpdate
              handleClick={() => {
                navigate('/identificacao-do-paciente', {
                  state: {
                    typeDocumentSelected,
                    docSelected,
                  },
                });
              }}
              margin="3rem 0 1.5rem 0"
              marginMobile="1rem 0 0rem 0"
            />
            <S.DeviceController isMobile={false}>
              <S.ListWrapper>
                <D.TmpList>
                  <ListItem
                    icon="fila"
                    iconReady={skipNurseScreening ? 'tolerancia' : 'chat'}
                    showLabelQueue
                  />
                  <ListItem
                    icon="chat"
                    iconReady={skipNurseScreening ? 'chat' : 'tolerancia'}
                    label={
                      'Você será direcionado automaticamente\nà vídeochamada em uma nova aba do seu navegador'
                    }
                    labelReady="Inicie seu atendimento em até 15 minutos para não perder sua vez"
                  />
                  <ListItem
                    icon="tolerancia"
                    iconReady="celular"
                    label={
                      'Inicie seu atendimento em até 15 minutos para não\nperder sua vez'
                    }
                    labelReady={labelQRCode()}
                  />
                </D.TmpList>
              </S.ListWrapper>
            </S.DeviceController>
            <S.DeviceController isMobile>
              <S.ListWrapper>
                <D.TmpList>
                  <ListItem
                    icon="fila"
                    iconReady={skipNurseScreening ? 'tolerancia' : 'perfil'}
                    showLabelQueue
                    mobile
                  />
                  <ListItem
                    icon="chat"
                    iconReady={skipNurseScreening ? 'chat' : 'tolerancia'}
                    label={
                      'Você será direcionado automaticamente\nà vídeochamada em uma nova aba do seu navegador'
                    }
                    labelReady={
                      skipNurseScreening
                        ? 'Você será direcionado automaticamente\nà vídeochamada em uma nova aba do seu navegador'
                        : 'Inicie seu atendimento em até 15 minutos para não perder sua vez'
                    }
                  />
                  <ListItem
                    icon="tolerancia"
                    iconReady="celular"
                    label="Inicie seu atendimento em até 15 minutos para não perder sua vez"
                    labelReady={labelQRCode()}
                  />
                </D.TmpList>
              </S.ListWrapper>
            </S.DeviceController>
            <S.SoundNotification ref={audioPlayer} src={notificationSound} />
          </S.InformationWrapper>
        </S.Wrapper>
        <S.ModalWrapper>
          <DB2B.TmpModal
            isOpened={showInstructions}
            labelPrimary="Ok, entendi"
            labelSecondary="Fechar"
            isDoubleAction
            footerChildren={
              <S.DoNotShowInstructions>
                <D.TmpCheckbox
                  label="Não mostrar isso novamente"
                  name="doNotShowInstructions"
                  checked={doNotShowInstructions}
                  handleChange={() => {
                    setDoNotShowInstructions(value => !value);
                  }}
                />
              </S.DoNotShowInstructions>
            }
            title="Instruções"
            handleConfirm={() => {
              handleUnderstand();
            }}
            handleClose={() => {
              handleUnderstand();
            }}
          >
            <S.ModalSubtitleWrapper>
              <D.TmpTypography component="paragraph" size="small">
                Antes de seguir, temos algumas instruções:
              </D.TmpTypography>
            </S.ModalSubtitleWrapper>
            <S.ModalListWrapper>
              <D.TmpList>
                <D.TmpListItem
                  handleClick={() => {}}
                  label="Esteja em um local silencioso e iluminado"
                  leadingIcon={<ReactSVG src={I.ambienteiluminado} />}
                />
                <D.TmpListItem
                  handleClick={() => {}}
                  label="Vista-se adequadamente para o Atendimento Online"
                  leadingIcon={<ReactSVG src={I.vestimenta} />}
                />
                <D.TmpListItem
                  handleClick={() => {}}
                  label="Tenha uma boa conexão de Internet"
                  leadingIcon={<ReactSVG src={I.wifi} />}
                />
                <D.TmpListItem
                  handleClick={() => {}}
                  label="Permita o uso da câmera e do microfone em seu computador/celular"
                  leadingIcon={<ReactSVG src={I.permissaovideo} />}
                />
              </D.TmpList>
            </S.ModalListWrapper>
          </DB2B.TmpModal>
          <D.TmpDrawerContent
            title="Deseja mesmo sair do atendimento?"
            label="Ao sair, você cancelará seu atendimento e retornará para a tela de início."
            buttonLabelPrimary="Sim, quero sair"
            buttonLabelSecondary="Não, desejo permanecer"
            handleButtonClickPrimary={() => {
              setOpenedDrawer(false);
              handleLeave();
            }}
            handleClose={() => {
              setOpenedDrawer(false);
            }}
            isOpened={openedDrawer}
          />
        </S.ModalWrapper>
      </Components.Layout>
    </Components.LayoutSeparate>
  );
}
