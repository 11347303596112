import axios from 'axios';

const SECONDS_TIMEOUT = 60;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SHORTENER,
  headers: {
    'Content-type': 'application/json',
    Accept: '*/*',
  },
  timeout: SECONDS_TIMEOUT * 1000,
});

export async function getShortenURL(hashURL) {
  const { data } = await api.get(`/url?hash=${hashURL}`);
  return data.result;
}
