import { get, post } from './api_methods';

async function getPatient(type, document) {
  const { data } = await get(`patient?${type}=${document}`);
  return data.result;
}

async function postPatient(params) {
  const { data } = await post(`patient/register`, params);
  return data.result;
}

async function getVideoRoom(
  patientCode,
  agentCode,
  descriptionComplaint,
  instituteCode,
  skipNurseScreening,
  specialtyCode
) {
  const { data } = await post(`attendance/start`, {
    patientCode: patientCode.toString(),
    agentCode: agentCode.toString(),
    descriptionComplaint: descriptionComplaint.toString(),
    instituteCode: instituteCode.toString(),
    skipNurseScreening: skipNurseScreening.toString(),
    specialtyCode: specialtyCode.toString(),
  });
  return data.result;
}

async function sendSMSVideoRoom(
  patientCode,
  nameCompanion,
  phoneNumberCompanion,
  videoRoomURL
) {
  const { data } = await post(`/attendance/send-sms`, {
    patientCode,
    nameCompanion,
    phoneNumberCompanion,
    videoRoomURL,
  });
  return data.result;
}

export { getPatient, postPatient, getVideoRoom, sendSMSVideoRoom };
