import { useEffect, useState } from 'react';

import { Hooks, Utils } from '@b2b/core';
import { getHealthInsurance } from '@b2b/core/src/services/healthInsurance.service';

export function InstituteData(handleGetInstitution, institute, instituteCode) {
  const [insurance, setInsurance] = useState({});
  const {
    name,
    fantasyName,
    status,
    dddPhoneNumber,
    ddiPhoneNumber,
    phoneNumber,
    email,
    cep,
    city,
    state,
    neighborhood,
    address,
    addressNumber,
    complement,
    hasCompanionRequired,
  } = institute;
  const { document, typeDocument } = Utils.completeDocument(institute);
  const documentFormatted =
    typeDocument === 'CNPJ' ? Utils.formatCNPJ(document) : document;
  const { stateDescriptionByInitials } = Hooks.useStateList();

  const { specialties } = Hooks.useUser();

  const adaptSpecialties = specialties.map(item => item.description).join(', ');

  useEffect(() => {
    handleGetInstitution();
    getHealthInsurance(instituteCode)
      .then(response => {
        setInsurance({
          convention: response?.healthInsurance || '',
          category: response?.healthCategory || '',
          plan: response?.healthPlan || '',
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  const getInstituteData = [
    {
      class: 'instituteDataLabelCorporateName',
      type: 'heading',
      content: 'Razão social',
    },
    {
      class: 'institutionDataCorporateName',
      editClass: 'editInstituteDataCorporateName',
      type: 'paragraph',
      content: name,
      label: 'Razão social',
      name: 'corporateName',
      placeholder: 'Nome da instituição',
      disabled: true,
      capitalize: true,
    },
    {
      class: 'institutionDataLabelName',
      type: 'heading',
      content: 'Nome fantasia',
    },
    {
      class: 'institutionDataName',
      editClass: 'editInstituteDataName',
      type: 'paragraph',
      content: fantasyName,
      label: 'Nome fantasia',
      name: 'fantasyName',
      placeholder: 'Nome da instituição',
      disabled: true,
      capitalize: true,
    },
    {
      class: 'institutionDataLabelStatus',
      type: 'heading',
      content: 'Status',
    },
    {
      class: 'institutionDataStatus',
      editClass: 'editInstituteDataStatus',
      type: 'paragraph',
      content: status === 'A' ? 'Ativo' : 'Inativo',
      label: 'Status',
      name: 'statusInstitute',
      placeholder: 'Selecione o status',
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'institutionDataLabelDocument',
      type: 'heading',
      content: 'Documento',
    },
    {
      class: 'institutionDataDocument',
      editClass: 'editInstituteDataDocument',
      type: 'paragraph',
      content: 'CNPJ',
      label: 'Documento',
      name: 'typeDocumentInstitute',
      placeholder: 'Selecione o tipo do documento',
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'institutionDataLabelNumberDocument',
      type: 'heading',
      content: 'Nº do documento',
    },
    {
      class: 'institutionDataNumberDocument',
      editClass: 'editInstituteDataNumberDocument',
      type: 'paragraph',
      content: documentFormatted,
      label: 'Nº do documento',
      name: 'documentInstitute',
      placeholder: 'Digite o número do documento',
      disabled: true,
      mask: 'cnpj',
    },
    {
      class: 'institutionDataLabelEmail',
      type: 'heading',
      content: 'E-mail',
    },
    {
      class: 'institutionDataEmail',
      editClass: 'editInstituteDataEmail',
      type: 'paragraph',
      content: email,
      label: 'E-mail',
      name: 'emailInstitute',
      placeholder: 'Digite o e-mail',
      disabled: true,
    },
    // {
    //   class: 'institutionDataLabelPhone',
    //   type: 'heading',
    //   content: 'Telefone',
    // },
    // {
    //   class: 'institutionDataPhone',
    //   editClass: 'editInstituteDataPhone',
    //   type: 'paragraph',
    //   content: Utils.formatCellphone(
    //     `${ddiPhoneNumber}${dddPhoneNumber}${phoneNumber}`
    //   ),
    //   label: 'Telefone',
    //   name: 'telPhoneInstitute',
    //   placeholder: '+55 (00) 00000-0000',
    //   mask: 'ddiphone',
    //   disabled: true,
    // },
    {
      class: 'institutionDataLabelCelPhone',
      type: 'heading',
      content: 'Celular',
    },
    {
      class: 'institutionDataCelPhone',
      editClass: 'editInstituteDataCelPhone',
      type: 'paragraph',
      content: Utils.formatCellphone(
        `${ddiPhoneNumber}${dddPhoneNumber}${phoneNumber}`
      ),
      label: 'Celular',
      name: 'phoneNumberInstitute',
      placeholder: '+55 (00) 00000-0000',
      mask: 'ddiphone',
      disabled: true,
    },
    {
      class: 'institutionDataLabelCEP',
      type: 'heading',
      content: 'CEP',
    },
    {
      class: 'institutionDataCEP',
      editClass: 'editInstituteDataCEP',
      type: 'paragraph',
      content: Utils.formatCEP(cep),
      label: 'CEP',
      name: 'cepInstitute',
      placeholder: '00000-000',
      mask: 'cep',
      disabled: true,
    },
    {
      class: 'instituteDataLabelState',
      type: 'heading',
      content: 'Estado',
    },
    {
      class: 'instituteDataState',
      editClass: 'editInstituteDataState',
      type: 'paragraph',
      content: stateDescriptionByInitials(state),
      label: 'Estado',
      name: 'stateInstitute',
      placeholder: 'Selecione o Estado',
      typeInput: 'select',
      disabled: true,
    },
    {
      class: 'institutionDataLabelCity',
      type: 'heading',
      content: 'Cidade',
    },
    {
      class: 'institutionDataCity',
      editClass: 'editInstituteDataCity',
      type: 'paragraph',
      content: city,
      label: 'Cidade',
      name: 'cityInstitute',
      placeholder: 'Digite a cidade',
      disabled: true,
      typeInput: 'select',
    },
    {
      class: 'institutionDataLabelDistrict',
      type: 'heading',
      content: 'Bairro',
    },
    {
      class: 'institutionDataDistrict',
      editClass: 'editInstituteDataNeighborhood',
      type: 'paragraph',
      content: neighborhood,
      label: 'Bairro',
      name: 'neighborhoodInstitute',
      placeholder: 'Digite o bairro',
      disabled: true,
    },
    {
      class: 'institutionDataLabelPublicPlace',
      type: 'heading',
      content: 'Logradouro',
    },
    {
      class: 'institutionDataPublicPlace',
      editClass: 'editInstituteDataAddress',
      type: 'paragraph',
      content: Utils.capitalize(address),
      label: 'Logradouro',
      name: 'addressInstitute',
      placeholder: 'Digite o logradouro',
      capitalize: true,
      disabled: true,
    },
    {
      class: 'institutionDataLabelNumber',
      type: 'heading',
      content: 'Número',
    },
    {
      class: 'institutionDataNumber',
      editClass: 'editInstituteDataAddressNumber',
      type: 'paragraph',
      content: addressNumber,
      label: 'Número',
      name: 'addressNumberInstitute',
      placeholder: 'Digite o número',
      disabled: true,
    },
    {
      class: 'institutionDataLabelComplement',
      type: 'heading',
      content: 'Complemento',
    },
    {
      class: 'institutionDataComplement',
      editClass: 'editInstituteDataComplement',
      type: 'paragraph',
      content: Utils.capitalize(complement) || '--',
      label: 'Complemento',
      name: 'complementInstitute',
      placeholder: Utils.capitalize(complement) || '--',
      capitalize: true,
      disabled: true,
    },
    {
      class: 'instituteDataLabelConvention',
      type: 'heading',
      content: 'Convênio',
    },
    {
      class: 'instituteDataConvention',
      editClass: 'editInstituteDataConvention',
      type: 'paragraph',
      content: insurance.convention,
      label: 'Convênio',
      name: 'convention',
      placeholder: insurance.convention
        ? insurance.convention
        : 'Selecione o convênio',
      typeInput: 'select',
      disabled: true,
    },
    {
      class: 'instituteDataLabelCategory',
      type: 'heading',
      content: 'Categoria',
    },
    {
      class: 'instituteDataCategory',
      editClass: 'editInstituteDataCategory',
      type: 'paragraph',
      content: insurance.category,
      label: 'Categoria',
      name: 'category',
      placeholder: insurance.category
        ? insurance.category
        : 'Selecione a categoria',
      typeInput: 'select',
      disabled: true,
    },
    {
      class: 'instituteDataLabelPlan',
      type: 'heading',
      content: 'Plano',
    },
    {
      class: 'instituteDataPlan',
      editClass: 'editInstituteDataPlan',
      type: 'paragraph',
      content: insurance.plan,
      label: 'Plano',
      name: 'plan',
      placeholder: insurance.plan ? insurance.plan : 'Selecione o plano',
      typeInput: 'select',
      disabled: true,
    },
    {
      class: 'instituteDataLabelCompanion',
      type: 'heading',
      content: 'Acompanhante Digital obrigatório?',
    },
    {
      class: 'instituteDataCompanion',
      editClass: 'editInstituteDataCompanion',
      type: 'paragraph',
      content: hasCompanionRequired === 0 ? 'Não' : 'Sim',
      label: 'Acompanhante Digital obrigatório?',
      name: 'hasCompanionRequired',
      placeholder: 'Selecione uma opção',
      disabled: true,
      listItem: [
        {
          label: 'Não',
          value: '0',
        },
        {
          label: 'Sim',
          value: '1',
        },
      ],
      typeInput: 'select',
    },
    {
      class: 'instituteDataLabelSpecialties',
      type: 'heading',
      content: 'Especialidades atendidas',
    },
    {
      class: 'instituteDataSpecialties',
      editClass: 'editInstituteDataSpecialties',
      type: 'paragraph',
      content:
        adaptSpecialties.length > 0
          ? `${adaptSpecialties}.`
          : 'Nenhuma especialidade selecionada',
      listItems: specialties ?? [],
      mainKey: 'specialtyCode',
      selectedKey: 'selected',
      label: 'Especialidades Atendidas',
      name: 'specialties',
      showSelectedAsFalse: true,
      handleChange: () => {},
      disabled: true,
      startLines: 3,
      maxColumns: 6,
      typeInput: 'checkboxGroup',
    },
  ];
  if (institute) {
    return getInstituteData;
  }
  return false;
}
