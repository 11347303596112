import { useEffect, useState } from 'react';

import { Components } from '@b2b/core';
import { useNavigate, useParams } from 'react-router-dom';
import { getShortenURL } from 'services/shortenUrl.service';

export function VideoRoom() {
  const { hashURL } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorButtonLoading, setErrorButtonLoading] = useState(false);
  const navigate = useNavigate();

  function handleShortURL() {
    setLoading(true);
    setErrorButtonLoading(true);
    getShortenURL(hashURL)
      .then(result => {
        setError(false);
        window.location.href = result.url_destino;
        return null;
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
        setErrorButtonLoading(false);
      });
  }
  useEffect(() => {
    if (!hashURL) navigate('/login');
    handleShortURL();
  }, []);
  return (
    <Components.Layout>
      {loading && !error && <Components.Loading height="calc(100vh - 105px)" />}
      {error && (
        <Components.ReloadError
          reload={() => handleShortURL()}
          loading={errorButtonLoading}
          marginHorizontal="32px"
          marginTop="32px"
          height="calc(100vh - 153px)"
          customText="Falha ao redirecionar sala de vídeo"
        />
      )}
    </Components.Layout>
  );
}
