import { Utils } from '@b2b/core';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  gap: 10rem;
  padding: 5rem 16rem 0 4rem;
  justify-content: center;
  @media ${Utils.device.laptop} {
    gap: 0px;
    padding: 2rem 1.5rem;
  }
`;

export const ImageContainer = styled.div``;

export const ImageView = styled.img`
  @media ${Utils.device.laptop} {
    display: none;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 390px;
  max-height: 350px;
  margin-top: 2.5rem;
  gap: 2rem;
  @media ${Utils.device.laptop} {
    margin-top: 0.5rem;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    color: #292929;
    margin-bottom: 1rem;
  }
  @media ${Utils.device.laptop} {
    h1 {
      color: #292929;
      margin-bottom: 0.5rem;
    }
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  > div {
    height: 120px;
  }
  @media ${Utils.device.laptop} {
    gap: 0;
  }
`;

export const SelectContent = styled.div`
  button {
    min-width: none;
    width: 100%;
  }
`;

export const InputContent = styled.div``;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  height: 56px;
  margin-top: -4rem;
  width: 100%;
  .formButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 850px;
    min-width: 772px;
  }
  @media ${Utils.device.laptop} {
    width: 100%;
    margin-top: 0;
    .formButton {
      display: flex;
      width: 100%;
      max-width: 390px;
      min-width: 312px;
      justify-content: center;
      padding: 0 1.5rem;
    }
    button {
      width: 100%;
    }
  }
`;
